import React from "react";
import "./result.scss";
import withRouter from "@/app/comRouter/withRouter";
import Header from "@/layouts/comHeader/Header";
import Func from "@/app/comFunc/Func";
import DiceResult from "@/components/comDiceResult/DiceResult";

class ResultGameThreeDiceThreeMin extends React.Component {
    constructor(props) {
        super(props);
        window.ResultGameThreeDiceThreeMin = this;
        this.state = {
            games: undefined,
        };
    }
    _isMounted = false;
    componentDidMount = () => {
        this._isMounted = true;
        this.load();
    };
    componentWillUnmount() {
        this._isMounted = false;
    }
    load = () => {
        Func.send(
            Func.getApi("client.mGame.result.game", "path", {
                game: "game_three_dice_three_min",
            }),
            Func.getApi("client.mGame.result.game", "method"),
            null,
            (res) => {
                if (res.data && res.data.data) {
                    let data = res.data.data;
                    this._isMounted &&
                        this.setState({
                            ...this.state,
                            games: data,
                        });
                }
            },
            (res) => {
                console.clear();
            }
        );
    };
    render() {
        return (
            <React.Fragment>
                <Header
                    heading={Func.getRoute(
                        "result.GameThreeDiceThreeMin",
                        "text"
                    )}
                ></Header>
                <div className="result-game">
                    {this.state.games && this.state.games.length ? (
                        this.state.games.map((game, index) => {
                            return (
                                <div
                                    key={game._id}
                                    className="result-game__item"
                                >
                                    <div className="result-game__item-row">
                                        <span>Kỳ: {game.no}</span>
                                        <DiceResult
                                            array={[
                                                game.bom_1,
                                                game.bom_2,
                                                game.bom_3,
                                            ]}
                                        ></DiceResult>
                                    </div>
                                    <div className="result-game__item-row">
                                        <span>
                                            Bắt đầu:{" "}
                                            {Func.formatDate(game.start)}
                                        </span>
                                        <span>
                                            Kết thúc:{" "}
                                            {Func.formatDate(game.end)}
                                        </span>
                                    </div>
                                </div>
                            );
                        })
                    ) : (
                        <React.Fragment></React.Fragment>
                    )}
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(React.memo(ResultGameThreeDiceThreeMin));
