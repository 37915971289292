import Func from "@/app/comFunc/Func";
import React from "react";
import "./text.scss";

class Text extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    filterChildren = () => {
        let result = this.props.children;
        if (result && typeof result === "object") {
            result = result.join("");
        }
        return result;
    };
    render() {
        return (
            <span
                {...this.props}
                title={
                    this.props.title
                        ? this.props.title
                        : Func.stripHtml(
                              this.filterChildren(this.props.children)
                          )
                }
                onClick={(event) =>
                    Func.copy(
                        this.props.title
                            ? this.props.title
                            : Func.stripHtml(
                                  this.filterChildren(this.props.children)
                              )
                    )
                }
            >
                {this.props.children}
            </span>
        );
    }
}

export default Text;
