import React from "react";
import "./game.scss";
import withRouter from "@/app/comRouter/withRouter";
import { Link, NavLink } from "react-router-dom";
import Icon from "@/components/comIcon/Icon";
import Func from "@/app/comFunc/Func";
import { gameTatCa } from "../comHome/Home";

class GameHead extends React.Component {
    constructor(props) {
        super(props);
        window.GameHead = this;
        this.state = {
            is_login: false,
            id: Func.getStorage("id"),
            username: Func.getStorage("username"),
            balance: Func.getStorage("balance"),
            image: Func.getStorage("image"),
            vip: Func.getStorage("vip"),
            menuIsOpen: false,
        };
    }
    _isMounted = false;
    componentDidMount = () => {
        this._isMounted = true;
        this.interval = setInterval(this.check, 1);
    };
    componentWillUnmount = () => {
        this._isMounted = false;
        clearInterval(this.interval);
    };
    check = () => {
        if (Func.getStorage("id") !== this.state.id) {
            this._isMounted &&
                this.setState({
                    ...this.state,
                    id: Func.getStorage("id"),
                });
        }
        if (Func.getStorage("username") !== this.state.username) {
            this._isMounted &&
                this.setState({
                    ...this.state,
                    username: Func.getStorage("username"),
                });
        }
        if (Func.getStorage("balance") !== this.state.balance) {
            this._isMounted &&
                this.setState({
                    ...this.state,
                    balance: Func.getStorage("balance"),
                });
        }
        if (Func.getStorage("image") !== this.state.image) {
            this._isMounted &&
                this.setState({
                    ...this.state,
                    image: Func.getStorage("image"),
                });
        }
        if (Func.getStorage("vip") !== this.state.vip) {
            this._isMounted &&
                this.setState({
                    ...this.state,
                    vip: Func.getStorage("vip"),
                });
        }
        if (
            Func.getStorage("token") &&
            Func.getStorage("token_expired") === false
        ) {
            this.state.is_login !== true &&
                this._isMounted &&
                this.setState({
                    ...this.state,
                    is_login: true,
                });
            return true;
        } else {
            this.state.is_login !== false &&
                this._isMounted &&
                this.setState({
                    ...this.state,
                    is_login: false,
                });
            return false;
        }
    };
    HeaderInfo = () => {
        return (
            <Link to={Func.getRoute("account")} className="game-head__right">
                <div className="game-head__right-info">
                    <div>
                        <img
                            src={
                                "images/vips/vip" +
                                (this.state.vip ? this.state.vip : 1) +
                                ".png"
                            }
                            style={{ height: "15px" }}
                            alt=""
                        />
                        <strong>
                            {this.state.username ? this.state.username : "..."}
                        </strong>
                    </div>
                    <span>
                        <i>Số dư: </i>
                        {this.state.balance
                            ? Func.formatBalance(this.state.balance)
                            : "..."}
                    </span>
                </div>
                <img
                    src={
                        this.state.image
                            ? Func.getImage(this.state.image)
                            : Func.getAvatarDefault()
                    }
                    alt=""
                />
            </Link>
        );
    };
    openMenu = () => {
        this._isMounted && this.setState({ menuIsOpen: true });
    };
    closeMenu = () => {
        this._isMounted && this.setState({ menuIsOpen: false });
    };
    render() {
        return (
            <React.Fragment>
                <div {...this.props} className="game-head">
                    <Link
                        to={Func.getRoute("home")}
                        className="game-head__left"
                        title="Về trang chủ"
                    >
                        <Icon type="fas" className="fa-arrow-left"></Icon>
                    </Link>
                    <div className="game-head__middle" onClick={this.openMenu}>
                        {this.props.heading ? (
                            <h3>{this.props.heading}</h3>
                        ) : (
                            <h3>Trò chơi</h3>
                        )}
                        <Icon type="far" className="fa-swords"></Icon>
                    </div>
                    <this.HeaderInfo></this.HeaderInfo>
                </div>
                {this.state.menuIsOpen && (
                    <div className="game-menu animate__animated animate__bounceInLeft animate__faster">
                        <div className="game-menu__head">
                            <span>Chọn trò chơi</span>
                            <Icon
                                type="fas"
                                className="fa-xmark"
                                onClick={this.closeMenu}
                            ></Icon>
                        </div>
                        <div className="game-menu__content">
                            {gameTatCa.map((game, index) => {
                                return game.enabled ? (
                                    <div
                                        key={game.name + "_enabled_" + index}
                                        className="game-menu__item"
                                    >
                                        <NavLink
                                            to={
                                                game.linkBet
                                                    ? Func.getRoute(
                                                          game.linkBet
                                                      )
                                                    : "#"
                                            }
                                            className="game-menu__item-info"
                                        >
                                            <img
                                                src={game.image}
                                                alt={game.name}
                                            />
                                            <h3>{game.name}</h3>
                                            <span>{game.meta}</span>
                                        </NavLink>
                                        {/* <NavLink
                                 to={game.linkBet ? Func.getRoute(game.linkBet) : '#'}
                                 className="game-menu__item-bet"
                              >
                                 Cược ngay
                              </NavLink> */}
                                    </div>
                                ) : (
                                    <React.Fragment
                                        key={game.name + "_enabled_" + index}
                                    ></React.Fragment>
                                );
                            })}
                        </div>
                    </div>
                )}
            </React.Fragment>
        );
    }
}

export default withRouter(React.memo(GameHead));
