import React from "react";
import "./bank.scss";
import withRouter from "@/app/comRouter/withRouter";
import Header from "@/layouts/comHeader/Header";
import Button from "@/components/comButton/Button";
import Partner from "@/screens/comAuth/Partner";
import { Navigate } from "react-router";
import { PageSlide, PageWelcome } from "@/screens/comHome/Home";
import Form from "@/components/comForm/Form";
import Input from "@/components/comForm/comInput/Input";
import Func from "@/app/comFunc/Func";
import Option from "@/components/comForm/comOption/Option";

class Bank extends React.Component {
    constructor(props) {
        super(props);
        window.Bank = this;
        this.state = {
            submited: false,
            bank_id: null,
            bank_number: null,
            bank_user: null,
            check_bank: null,
            errors: {},
            isSending: false,
        };
    }
    _isMounted = false;
    componentDidMount = () => {
        this._isMounted = true;
        this.load();
    };
    componentWillUnmount() {
        this._isMounted = false;
    }
    load = () => {
        this._isMounted &&
            this.setState({
                ...this.state,
                isSending: true,
            });
        Func.send(
            Func.getApi("client.mACL.customer.info", "path", {
                customer: Func.getStorage("id"),
            }),
            Func.getApi("client.mACL.customer.info", "method"),
            null,
            (res) => {
                if (res.data && res.data.data) {
                    let data = res.data.data;
                    this._isMounted &&
                        this.setState({
                            ...this.state,
                            bank_id: data.bank_id,
                            bank_number: data.bank_number,
                            bank_user: data.bank_user,
                            check_bank: data.check_bank,
                            banks: res.data.attach,
                        });
                    Func.setUserStorage(data);
                }
            },
            (res) => {}
        );
    };
    onSubmitBank = (event) => {
        event.preventDefault();
        Func.send(
            Func.getApi("client.mACL.customer.update", "path", {
                customer: Func.getStorage("id"),
            }),
            Func.getApi("client.mACL.customer.update", "method"),
            {
                bank_id: this.state.bank_id,
                bank_number: this.state.bank_number,
                bank_user: this.state.bank_user,
            },
            (res) => {
                if (res.data.code === 200) {
                    res.data.message && Func.notifySuccess(res.data.message);
                    this._isMounted &&
                        this.setState({
                            bank_id: null,
                            bank_number: null,
                            bank_user: null,
                            check_bank: null,
                            submited: true,
                        });
                } else {
                    Func.handleErrors(res, this);
                }
            },
            (res) => {
                Func.handleErrors(res, this);
            }
        );
    };
    render() {
        return this.state.submited ? (
            <Navigate to={Func.getRoute("account")}></Navigate>
        ) : (
            <React.Fragment>
                <Header
                    heading={Func.getRoute("account.bank", "text")}
                ></Header>
                <div className="bank">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <PageSlide></PageSlide>
                                <PageWelcome></PageWelcome>
                                <Form
                                    onSubmit={this.onSubmitBank}
                                    id="form"
                                    className="form"
                                >
                                    {this.state.banks && (
                                        <div className="form__item">
                                            <div className="form__item-title">
                                                Chọn ngân hàng
                                                <span className="form__item-required">
                                                    *
                                                </span>
                                            </div>
                                            <div className="form__item-field">
                                                <Option
                                                    value={this.state.banks.filter(
                                                        ({ value }) =>
                                                            value ===
                                                            parseInt(
                                                                this.state
                                                                    .bank_id
                                                            )
                                                    )}
                                                    placeholder="Chọn ngân hàng"
                                                    name="bank_id"
                                                    onChange={(event) =>
                                                        Func.setValueOption(
                                                            event,
                                                            this,
                                                            "bank_id"
                                                        )
                                                    }
                                                    isSearchable={true}
                                                    options={this.state.banks}
                                                    className={
                                                        this.state.errors
                                                            .bank_id &&
                                                        "validator__error"
                                                    }
                                                    disabled={
                                                        this.state.check_bank
                                                            ? true
                                                            : false
                                                    }
                                                    readOnly={
                                                        this.state.check_bank
                                                            ? true
                                                            : false
                                                    }
                                                ></Option>
                                            </div>
                                            {this.state.errors.bank_id && (
                                                <div className="validator__error">
                                                    {this.state.errors.bank_id}
                                                </div>
                                            )}
                                        </div>
                                    )}
                                    <div className="form__item">
                                        <div className="form__item-title">
                                            Chủ tài khoản
                                            <span className="form__item-required">
                                                *
                                            </span>
                                        </div>
                                        <div className="form__item-field">
                                            <Input
                                                format="ALPHABET"
                                                max="100"
                                                onInput={(event) =>
                                                    Func.setValueInput(
                                                        event,
                                                        this
                                                    )
                                                }
                                                autoComplete="new-password"
                                                name="bank_user"
                                                placeholder="Từ 3 tới 100 ký tự"
                                                value={this.state.bank_user}
                                                className={
                                                    this.state.errors
                                                        .bank_user &&
                                                    "validator__error"
                                                }
                                                disabled={
                                                    this.state.check_bank
                                                        ? true
                                                        : false
                                                }
                                                readOnly={
                                                    this.state.check_bank
                                                        ? true
                                                        : false
                                                }
                                            ></Input>
                                        </div>
                                        {this.state.errors.bank_user && (
                                            <div className="validator__error">
                                                {this.state.errors.bank_user}
                                            </div>
                                        )}
                                    </div>
                                    <div className="form__item">
                                        <div className="form__item-title">
                                            Số tài khoản
                                            <span className="form__item-required">
                                                *
                                            </span>
                                        </div>
                                        <div className="form__item-field">
                                            <Input
                                                format="Alphadash"
                                                max="100"
                                                nospace="true"
                                                onInput={(event) =>
                                                    Func.setValueInput(
                                                        event,
                                                        this
                                                    )
                                                }
                                                autoComplete="new-password"
                                                name="bank_number"
                                                placeholder="Từ 3 tới 100 ký tự"
                                                value={this.state.bank_number}
                                                className={
                                                    this.state.errors
                                                        .bank_number &&
                                                    "validator__error"
                                                }
                                                disabled={
                                                    this.state.check_bank
                                                        ? true
                                                        : false
                                                }
                                                readOnly={
                                                    this.state.check_bank
                                                        ? true
                                                        : false
                                                }
                                            ></Input>
                                        </div>
                                        {this.state.errors.bank_number && (
                                            <div className="validator__error">
                                                {this.state.errors.bank_number}
                                            </div>
                                        )}
                                    </div>
                                    <div className="form__item">
                                        <Button
                                            className="btn btn__big"
                                            type="submit"
                                            disabled={
                                                this.state.bank_id &&
                                                this.state.bank_user &&
                                                this.state.bank_number &&
                                                !this.state.check_bank
                                                    ? false
                                                    : true
                                            }
                                            readOnly={
                                                this.state.bank_id &&
                                                this.state.bank_user &&
                                                this.state.bank_number &&
                                                !this.state.check_bank
                                                    ? false
                                                    : true
                                            }
                                        >
                                            Cập nhật thông tin ngân hàng
                                        </Button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                    <Partner></Partner>
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(React.memo(Bank));
