import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Master from "@/layouts/comMaster/Master";
import { Router } from "@/app/comConst/Const";
import Func from "@/app/comFunc/Func";
import "./router.scss";
import Login from "@/screens/comAuth/Login";

class AppRoute extends React.Component {
    constructor(props) {
        super(props);
        window.AppRoute = this;
        this.state = {
            is_login:
                Func.getStorage("token") &&
                Func.getStorage("token_expired") === false
                    ? true
                    : false,
            redirect: Func.getRoute("login"),
            home: Func.getRoute("home"),
        };
        this.routes = Func.getRoutes(Router);
    }
    _isMounted = false;
    componentDidMount = () => {
        this._isMounted = true;
        this.interval = setInterval(this.check, 1);
    };
    componentWillUnmount = () => {
        this._isMounted = false;
        clearInterval(this.interval);
    };
    check = () => {
        if (
            Func.getStorage("token") &&
            Func.getStorage("token_expired") === false
        ) {
            this.state.is_login !== true &&
                this._isMounted &&
                this.setState({
                    ...this.state,
                    is_login: true,
                });
            return true;
        } else {
            this.state.is_login !== false &&
                this._isMounted &&
                this.setState({
                    ...this.state,
                    is_login: false,
                });
            return false;
        }
    };
    renderRoute = (routes) => {
        return routes.map((route) => {
            let Screen = route.component;
            return (
                <Route
                    key={route.key}
                    path={route.path ? route.path : ""}
                    element={
                        route.redirect ? (
                            <Navigate replace to={route.redirect}></Navigate>
                        ) : route.private ? (
                            this.state.is_login ? (
                                <Screen></Screen>
                            ) : (
                                <Login></Login>
                            )
                        ) : (
                            <Screen></Screen>
                        )
                    }
                ></Route>
            );
        });
    };
    render() {
        return (
            <BrowserRouter>
                <Routes>
                    <Route path="*" element={<Master></Master>}>
                        {this.renderRoute(this.routes)}
                        {this.state.home && (
                            <Route
                                path="*"
                                element={
                                    <Navigate
                                        replace
                                        to={this.state.home}
                                    ></Navigate>
                                }
                            ></Route>
                        )}
                    </Route>
                </Routes>
            </BrowserRouter>
        );
    }
}
export default AppRoute;
