import React from "react";
import "./auth.scss";
import withRouter from "@/app/comRouter/withRouter";
import Form from "@/components/comForm/Form";
import Input from "@/components/comForm/comInput/Input";
import Func from "@/app/comFunc/Func";
import Icon from "@/components/comIcon/Icon";
import Button from "@/components/comButton/Button";
import Sending from "@/components/comSending/Sending";
import Validator from "@/app/comValidator/Validator";
import { Link, Navigate } from "react-router-dom";
import Header from "@/layouts/comHeader/Header";
import Partner from "./Partner";
import { PageSlide, PageWelcome } from "../comHome/Home";

const rules = [
    // {
    //    field: 'name',
    //    method: 'isEmpty',
    //    validWhen: false,
    //    message: 'Họ tên không được bỏ trống.',
    // },
    // {
    //    field: 'name',
    //    method: 'isLength',
    //    args: [{ max: 32 }],
    //    validWhen: true,
    //    message: 'Họ tên tối đa 32 ký tự.',
    // },
    {
        field: "username",
        method: "isEmpty",
        validWhen: false,
        message: "Tên đăng nhập không được bỏ trống.",
    },
    {
        field: "username",
        method: "isLength",
        args: [{ min: 3, max: 30 }],
        validWhen: true,
        message: "Tên đăng nhập từ 3 tới 30 ký tự.",
    },
    {
        field: "password_withdraw",
        method: "isEmpty",
        validWhen: false,
        message: "Mật khẩu rút tiền không được bỏ trống.",
    },
    {
        field: "password_withdraw",
        method: "isLength",
        args: [{ min: 3, max: 100 }],
        validWhen: true,
        message: "Mật khẩu rút tiền từ 3 tới 100 ký tự.",
    },
    {
        field: "password",
        method: "isEmpty",
        validWhen: false,
        message: "Mật khẩu không được bỏ trống.",
    },
    {
        field: "password",
        method: "isLength",
        args: [{ min: 6, max: 30 }],
        validWhen: true,
        message: "Mật khẩu từ 6 tới 30 ký tự.",
    },
    {
        field: "password_confirmation",
        method: "isEmpty",
        validWhen: false,
        message: "Nhắc lại mật khẩu không được bỏ trống.",
    },
    {
        field: "password_confirmation",
        method: "isLength",
        args: [{ min: 6, max: 30 }],
        validWhen: true,
        message: "Nhắc lại mật khẩu từ 6 tới 30 ký tự.",
    },
    //    {
    //       field: 'invite_from_code',
    //       method: 'isEmpty',
    //       validWhen: false,
    //       message: 'Mã giới thiệu không được bỏ trống.',
    //    },
    //    {
    //       field: 'invite_from_code',
    //       method: 'isLength',
    //       args: [{ max: 100 }],
    //       validWhen: true,
    //       message: 'Mã giới thiệu tối đa 100 ký tự.',
    //    },
//     {
//         field: "phone",
//         method: "isEmpty",
//         validWhen: false,
//         message: "Số điện thoại không được bỏ trống.",
//     },
];

class Register extends React.Component {
    constructor(props) {
        super(props);
        window.Register = this;
        this.state = {
            is_login: false,
            is_sending: Func.getStorage("is_sending"),
            // name: null,
            username: null,
            password_withdraw: null,
            password: null,
            password_confirmation: null,
            show_password_withdraw: false,
            show_password: false,
            show_password_confirmation: false,
            invite_from_code: null,
          //   phone: null,
            errors: {},
        };
        this.validator = new Validator(rules);
    }
    componentDidMount = () => {
        this._isMounted = true;
        this.interval = setInterval(this.check, 1);
    };
    _isMounted = false;
    componentWillUnmount = () => {
        this._isMounted = false;
        clearInterval(this.interval);
    };
    check = () => {
        if (
            Func.getStorage("token") &&
            Func.getStorage("token_expired") === false
        ) {
            this.state.is_login !== true &&
                this._isMounted &&
                this.setState({
                    ...this.state,
                    is_login: true,
                });
            return true;
        } else {
            this.state.is_login !== false &&
                this._isMounted &&
                this.setState({
                    ...this.state,
                    is_login: false,
                });
            return false;
        }
    };
    handleIsSending = (value) => {
        this.setState({
            ...this.state,
            is_sending: value ? true : false,
        });
    };
    handleShowPasswordWithdraw = (event) => {
        let checkClass = event.target.classList.contains("fa-eye");
        this.setState({
            ...this.state,
            show_password_withdraw: checkClass ? true : false,
        });
    };
    handleShowPassword = (event) => {
        let checkClass = event.target.classList.contains("fa-eye");
        this.setState({
            ...this.state,
            show_password: checkClass ? true : false,
        });
    };
    handleShowPasswordConfirm = (event) => {
        let checkClass = event.target.classList.contains("fa-eye");
        this.setState({
            ...this.state,
            show_password_confirmation: checkClass ? true : false,
        });
    };

    handleSubmit = (event) => {
        event.preventDefault();
        this.setState(
            {
                ...this.state,
                errors: this.validator.valid(this.state),
            },
            () => {
                if (Func.checkObjectEmpty(this.state.errors)) {
                    Func.send(
                        Func.getApi("auth.register"),
                        Func.getApi("auth.register", "method"),
                        {
                            name: this.state.username,
                            username: this.state.username,
                            password_withdraw: this.state.password_withdraw,
                            password: this.state.password,
                            password_confirmation:
                                this.state.password_confirmation,
                            invite_from_code: this.state.invite_from_code,
                         //    phone: this.state.phone,
                        },
                        (res) => {
                            if (res.data.data) {
                                let data = res.data.data;
                                Func.setUserStorage(data);
                                res.data.message &&
                                    Func.notifySuccess(res.data.message);
                            } else {
                                Func.handleErrors(res, this);
                            }
                        },
                        (res) => {
                            Func.handleErrors(res, this);
                        }
                    );
                }
            }
        );
    };

    render() {
        return this.state.is_login ? (
            <Navigate to={Func.getRoute("home")}></Navigate>
        ) : (
            <React.Fragment>
                <Header heading={Func.getRoute("register", "text")}></Header>
                <div id="auth" className="auth">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <PageSlide></PageSlide>
                                <PageWelcome></PageWelcome>
                                <Form
                                    onSubmit={this.handleSubmit}
                                    id="auth__form"
                                    className="auth__form"
                                >
                                    <div className="auth__form-content">
                                        {/* <div className="auth__form-item">
                                 <Input
                                    format="AlphabetVN"
                                    onInput={(event) => Func.setValueInput(event, this)}
                                    name="name"
                                    className={this.state.errors.name && 'validator__error'}
                                    placeholder="Họ tên thật (bắt buộc)"
                                    readOnly={this.state.is_sending}
                                    value={this.state.name}
                                 ></Input>
                                 {this.state.errors.name && (
                                    <div className="validator__error">{this.state.errors.name}</div>
                                 )}
                              </div> */}
                                        <div className="auth__form-item">
                                            <Input
                                                format="alphadash"
                                                onInput={(event) =>
                                                    Func.setValueInput(
                                                        event,
                                                        this
                                                    )
                                                }
                                                name="username"
                                                className={
                                                    this.state.errors
                                                        .username &&
                                                    "validator__error"
                                                }
                                                placeholder="Tên đăng nhập (họ và tên)"
                                                readOnly={this.state.is_sending}
                                                value={this.state.username}
                                            ></Input>
                                            {this.state.errors.username && (
                                                <div className="validator__error">
                                                    {this.state.errors.username}
                                                </div>
                                            )}
                                        </div>
                                        {/* <div className="auth__form-item">
                                            <Input
                                                format="phonevn"
                                                onInput={(event) =>
                                                    Func.setValueInput(
                                                        event,
                                                        this
                                                    )
                                                }
                                                name="phone"
                                                className={
                                                    this.state.errors.phone &&
                                                    "validator__error"
                                                }
                                                placeholder="Số điện thoại (bắt buộc)"
                                                readOnly={this.state.is_sending}
                                                value={this.state.phone}
                                            ></Input>
                                            {this.state.errors.phone && (
                                                <div className="validator__error">
                                                    {this.state.errors.phone}
                                                </div>
                                            )}
                                        </div> */}
                                        <div className="auth__form-item">
                                            <div className="auth__form-icon">
                                                <Input
                                                    onInput={(event) =>
                                                        Func.setValueInput(
                                                            event,
                                                            this
                                                        )
                                                    }
                                                    type={
                                                        this.state.show_password
                                                            ? "text"
                                                            : "password"
                                                    }
                                                    name="password"
                                                    autoComplete="new-password"
                                                    className={
                                                        this.state.errors
                                                            .password &&
                                                        "validator__error"
                                                    }
                                                    placeholder="Mật khẩu (bắt buộc)"
                                                    readOnly={
                                                        this.state.is_sending
                                                    }
                                                    value={this.state.password}
                                                ></Input>
                                                <Icon
                                                    type="fas"
                                                    onClick={
                                                        this.handleShowPassword
                                                    }
                                                    className={
                                                        this.state.show_password
                                                            ? "fa-eye-slash"
                                                            : "fa-eye"
                                                    }
                                                ></Icon>
                                            </div>
                                            {this.state.errors.password && (
                                                <div className="validator__error">
                                                    {this.state.errors.password}
                                                </div>
                                            )}
                                        </div>
                                        <div className="auth__form-item">
                                            <div className="auth__form-icon">
                                                <Input
                                                    onInput={(event) =>
                                                        Func.setValueInput(
                                                            event,
                                                            this
                                                        )
                                                    }
                                                    type={
                                                        this.state
                                                            .show_password_confirmation
                                                            ? "text"
                                                            : "password"
                                                    }
                                                    name="password_confirmation"
                                                    autoComplete="new-password"
                                                    className={
                                                        this.state.errors
                                                            .password_confirmation &&
                                                        "validator__error"
                                                    }
                                                    placeholder="Nhắc lại mật khẩu (bắt buộc)"
                                                    readOnly={
                                                        this.state.is_sending
                                                    }
                                                    value={
                                                        this.state
                                                            .password_confirmation
                                                    }
                                                ></Input>
                                                <Icon
                                                    type="fas"
                                                    onClick={
                                                        this
                                                            .handleShowPasswordConfirm
                                                    }
                                                    className={
                                                        this.state
                                                            .show_password_confirmation
                                                            ? "fa-eye-slash"
                                                            : "fa-eye"
                                                    }
                                                ></Icon>
                                            </div>
                                            {this.state.errors
                                                .password_confirmation && (
                                                <div className="validator__error">
                                                    {
                                                        this.state.errors
                                                            .password_confirmation
                                                    }
                                                </div>
                                            )}
                                        </div>
                                        {/* <div className="auth__form-item">
                                 <Input
                                    format="ALPHADASH"
                                    onInput={(event) => Func.setValueInput(event, this)}
                                    name="invite_from_code"
                                    autoComplete="new-password"
                                    className={this.state.errors.invite_from_code && 'validator__error'}
                                    placeholder="Mã giới thiêu (bắt buộc)"
                                    readOnly={this.state.is_sending}
                                    value={this.state.invite_from_code}
                                 ></Input>
                                 {this.state.errors.invite_from_code && (
                                    <div className="validator__error">{this.state.errors.invite_from_code}</div>
                                 )}
                              </div> */}
                                        <div className="auth__form-item">
                                            <div className="auth__form-icon">
                                                <Input
                                                    onInput={(event) =>
                                                        Func.setValueInput(
                                                            event,
                                                            this
                                                        )
                                                    }
                                                    type={
                                                        this.state
                                                            .show_password_withdraw
                                                            ? "text"
                                                            : "password"
                                                    }
                                                    name="password_withdraw"
                                                    autoComplete="new-password"
                                                    className={
                                                        this.state.errors
                                                            .password_withdraw &&
                                                        "validator__error"
                                                    }
                                                    placeholder="Mật khẩu rút tiền (bắt buộc)"
                                                    readOnly={
                                                        this.state.is_sending
                                                    }
                                                    value={
                                                        this.state
                                                            .password_withdraw
                                                    }
                                                ></Input>
                                                <Icon
                                                    type="fas"
                                                    onClick={
                                                        this
                                                            .handleShowPasswordWithdraw
                                                    }
                                                    className={
                                                        this.state
                                                            .show_password_withdraw
                                                            ? "fa-eye-slash"
                                                            : "fa-eye"
                                                    }
                                                ></Icon>
                                            </div>
                                            {this.state.errors
                                                .password_withdraw && (
                                                <div className="validator__error">
                                                    {
                                                        this.state.errors
                                                            .password_withdraw
                                                    }
                                                </div>
                                            )}
                                        </div>
                                        <div className="auth__form-item">
                                            <Button
                                                disabled={this.state.is_sending}
                                                type="submit"
                                                className="auth__form-button btn btn__warning btn__big"
                                                id="auth__form-button"
                                            >
                                                {this.state.is_sending ? (
                                                    <Sending></Sending>
                                                ) : (
                                                    "Đăng ký"
                                                )}
                                            </Button>
                                        </div>
                                    </div>
                                    <div className="auth__form-footer">
                                        <span>Đã có tài khoản ?</span>
                                        <Link to={Func.getRoute("login")}>
                                            Đăng nhập ngay
                                        </Link>
                                    </div>
                                    <Partner></Partner>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(React.memo(Register));
