import Image from "@/components/comImage/Image";
import Func from "@/app/comFunc/Func";
import React from "react";
import "./sending.scss";

class Sending extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    componentDidMount = () => {};

    componentWillUnmount = () => {
        Func.removeStorage("is_sending");
    };

    render() {
        return (
            <div {...this.props} className="sending">
                <Image className="" src={"/images/loader.gif"}></Image>
            </div>
        );
    }
}

export default Sending;
