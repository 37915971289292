import React from "react";
import "./page_404.scss";

class Page404 extends React.Component {
    constructor(props) {
        super(props);
        window.Page404 = this;
        this.state = {};
    }
    _isMounted = false;
    componentDidMount = () => {
        this._isMounted = true;
    };
    componentWillUnmount() {
        this._isMounted = false;
    }
    render() {
        return (
            <React.Fragment>
                <div className="page_404">
                    <img
                        src="/images/404.png"
                        alt="Thiết bị không phù hợp..."
                    />
                </div>
            </React.Fragment>
        );
    }
}

export default React.memo(Page404);
