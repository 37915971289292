import React from "react";
import "./promotion.scss";
import withRouter from "@/app/comRouter/withRouter";
import Header from "@/layouts/comHeader/Header";
import Func from "@/app/comFunc/Func";
import NoData from "@/components/comNoData/NoData";

class Promotion extends React.Component {
    constructor(props) {
        super(props);
        window.Promotion = this;
        this.state = {};
    }
    _isMounted = false;
    componentDidMount = () => {
        this._isMounted = true;
    };
    componentWillUnmount() {
        this._isMounted = false;
    }
    render() {
        return (
            <React.Fragment>
                <Header heading={Func.getRoute("promotion", "text")}></Header>
                <div className="promotion" style={{ padding: "10px" }}>
                    {/* <NoData></NoData> */}

                    <video
                        id="video_intro"
                        autoplay="autoplay"
                        loop
                        muted
                        style={{
                            borderRadius: "10px",
                            width: "100%",
                            height: "100%",
                        }}
                    >
                        <source
                            src="/images/KHUYEN_MAI_NEW.mp4"
                            type="video/mp4"
                        />
                    </video>
                    {/* <img
                        src="/images/photo_2024-03-02_16-36-46.jpg"
                        style={{
                            borderRadius: "10px",
                            width: "100%",
                            height: "100%",
                        }}
                        alt=""
                    /> */}
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(React.memo(Promotion));
