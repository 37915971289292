import React from "react";
import "./recharge.scss";
import withRouter from "@/app/comRouter/withRouter";
import Header from "@/layouts/comHeader/Header";
import Func, { RenderBankInfo } from "@/app/comFunc/Func";
import Partner from "@/screens/comAuth/Partner";
import { Navigate } from "react-router-dom";
import Button from "@/components/comButton/Button";
import { PageSlide, PageWelcome } from "@/screens/comHome/Home";
import Form from "@/components/comForm/Form";
import Input from "@/components/comForm/comInput/Input";
import Option from "@/components/comForm/comOption/Option";
import Sending from "@/components/comSending/Sending";
import Notify from "@/components/comNotify/Notify";
import Icon from "@/components/comIcon/Icon";
import Const from "@/app/comConst/Const";

class Recharge extends React.Component {
    constructor(props) {
        super(props);
        window.Recharge = this;
        this.state = {
            submited: false,
            balance: null,
            bankId: null,
            banksData: null,
            banks: null,
            errors: {},
            isSending: false,
        };
    }
    _isMounted = false;
    componentDidMount = () => {
        this._isMounted = true;
        this.load();
    };
    componentWillUnmount() {
        this._isMounted = false;
    }
    load = () => {
        this._isMounted &&
            this.setState({
                ...this.state,
                isSending: true,
            });
        Func.send(
            Func.getApi("client.mACL.recharge.create", "path"),
            Func.getApi("client.mACL.recharge.create", "method"),
            null,
            (res) => {
                if (res.data && res.data.data) {
                    let data = res.data.data;
                    this._isMounted &&
                        this.setState({
                            ...this.state,
                            banksData: data,
                            banks: data
                                ? data.map((item) => {
                                      return {
                                          value: item._id,
                                          label:
                                              "[STK: " +
                                              item.bank_number +
                                              "] - [" +
                                              item.bank_user +
                                              "] - [" +
                                              item.bank_name +
                                              "]",
                                      };
                                  })
                                : null,
                            isSending: false,
                        });
                }
            },
            (res) => {
                this._isMounted &&
                    this.setState({
                        ...this.state,
                        submited: true,
                        isSending: false,
                    });
                console.clear();
            }
        );
    };
    onSubmitRecharge = (event) => {
        event.preventDefault();
        if (
            this.state.balance &&
            Func.filterNumeric(this.state.balance) > 0 &&
            this.state.bankId
        ) {
            Func.confirmAlert(
                "Xác nhận rằng quý khách đã thanh toán chuyển khoản ? <br>Nếu phát hiện có hành vi xấu sẽ trực tiếp đóng băng tài khoản.",
                (confirmed) => {
                    if (confirmed) {
                        Func.send(
                            Func.getApi("client.mACL.recharge.store", "path"),
                            Func.getApi("client.mACL.recharge.store", "method"),
                            {
                                bank_id: this.state.bankId,
                                balance: this.state.balance,
                            },
                            (res) => {
                                if (res.data.code === 200) {
                                    res.data.message &&
                                        Func.notifySuccess(res.data.message);
                                    this._isMounted &&
                                        this.setState({
                                            balance: null,
                                            bankId: null,
                                            submited: true,
                                        });
                                } else {
                                    Func.handleErrors(res, this);
                                }
                            },
                            (res) => {
                                Func.handleErrors(res, this);
                            }
                        );
                    }
                }
            );
        }
    };
    handleChips = (event, money) => {
        event.preventDefault();
        if (money && this.state.bankId) {
            let balance = this.state.balance;
            if (balance) {
                money = Func.filterNumeric(money) + Func.filterNumeric(balance);
            }
            this._isMounted &&
                this.setState({
                    balance: money,
                });
            return true;
        }
        return false;
    };
    render() {
        return this.state.submited ? (
            <Navigate to={Func.getRoute("account.recharge.history")}></Navigate>
        ) : (
            <React.Fragment>
                <Header
                    heading={Func.getRoute("account.recharge", "text")}
                ></Header>
                <div className="recharge">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <PageSlide></PageSlide>
                                <PageWelcome></PageWelcome>
                                <Form
                                    onSubmit={this.onSubmitRecharge}
                                    id="form"
                                    className="form"
                                >
                                    <div className="form__item">
                                        <div className="form__item-title">
                                            Chọn kênh nạp tiền
                                            <span className="form__item-required">
                                                *
                                            </span>
                                        </div>
                                        <div className="form__item-field">
                                            {this.state.banks ? (
                                                <Option
                                                    value={
                                                        this.state.banks &&
                                                        this.state.banks.filter(
                                                            ({ value }) =>
                                                                value ===
                                                                this.state
                                                                    .bankId
                                                        )
                                                    }
                                                    placeholder="Chọn ngân hàng (bắt buộc)"
                                                    name="bankId"
                                                    onChange={(event) =>
                                                        Func.setValueOption(
                                                            event,
                                                            this,
                                                            "bankId"
                                                        )
                                                    }
                                                    isSearchable={true}
                                                    options={this.state.banks}
                                                    className={
                                                        this.state.errors
                                                            .bankId &&
                                                        "validator__error"
                                                    }
                                                ></Option>
                                            ) : this.state.isSending ? (
                                                <Sending
                                                    style={{
                                                        display: "flex",
                                                        minHeight: "42px",
                                                        justifyContent:
                                                            "center",
                                                        alignItems: "center",
                                                        width: "100%",
                                                    }}
                                                ></Sending>
                                            ) : (
                                                <Notify
                                                    className="notify__default"
                                                    style={{
                                                        marginBottom: "0px",
                                                    }}
                                                >
                                                    Tất cả kênh nạp tiền đều
                                                    đang bận, quý khách vui lòng
                                                    quay lại sau !
                                                </Notify>
                                            )}
                                        </div>
                                        {this.state.errors.bankId && (
                                            <div className="validator__error">
                                                {this.state.errors.bankId}
                                            </div>
                                        )}
                                    </div>
                                    <div className="form__item">
                                        <div className="form__item-title">
                                            Nhập số tiền nạp
                                            <span className="form__item-required">
                                                *
                                            </span>
                                        </div>
                                        <div className="form__item-field">
                                            <Input
                                                format="float"
                                                decimal={2}
                                                onInput={(event) =>
                                                    Func.setValueInput(
                                                        event,
                                                        this
                                                    )
                                                }
                                                suffix={" $"}
                                                name="balance"
                                                placeholder="Nhập số tiền..."
                                                value={this.state.balance}
                                                disabled={
                                                    this.state.bankId
                                                        ? false
                                                        : true
                                                }
                                                readOnly={
                                                    this.state.bankId
                                                        ? false
                                                        : true
                                                }
                                            ></Input>
                                            <Icon
                                                type="fas"
                                                onClick={() =>
                                                    this.state.balance &&
                                                    this._isMounted &&
                                                    this.setState({
                                                        balance: null,
                                                    })
                                                }
                                                className={
                                                    this.state.balance &&
                                                    Func.filterNumeric(
                                                        this.state.balance
                                                    ) > 0
                                                        ? "fa-eraser"
                                                        : "fa-keyboard"
                                                }
                                            ></Icon>
                                        </div>
                                        <div className="form__item-field">
                                            <div
                                                className={
                                                    "chips" +
                                                    (this.state.bankId
                                                        ? ""
                                                        : " disabled")
                                                }
                                            >
                                                {Const.chips.map((chip) => {
                                                    return (
                                                        <div
                                                            key={
                                                                "chip_" +
                                                                chip.key
                                                            }
                                                            className={
                                                                "chips__item chips__item-" +
                                                                chip.key
                                                            }
                                                            onClick={(event) =>
                                                                this.handleChips(
                                                                    event,
                                                                    chip.value
                                                                )
                                                            }
                                                        >
                                                            {chip.name}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                    <RenderBankInfo
                                        banksData={this.state.banksData}
                                        bankId={this.state.bankId}
                                        balance={this.state.balance}
                                    ></RenderBankInfo>
                                    <div className="form__item">
                                        <Button
                                            className="btn btn__big"
                                            type="submit"
                                            disabled={
                                                this.state.balance &&
                                                Func.filterNumeric(
                                                    this.state.balance
                                                ) > 0 &&
                                                this.state.bankId
                                                    ? false
                                                    : true
                                            }
                                            readOnly={
                                                this.state.balance &&
                                                Func.filterNumeric(
                                                    this.state.balance
                                                ) > 0 &&
                                                this.state.bankId
                                                    ? false
                                                    : true
                                            }
                                        >
                                            Gửi yêu cầu nạp tiền
                                        </Button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                    <Partner></Partner>
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(React.memo(Recharge));
