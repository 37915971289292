import React from "react";
import Icon from "../comIcon/Icon";
import "./notify.scss";

class Notify extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: true,
        };
        this.timer = null;
    }
    componentDidMount = () => {
        this.props.autohide &&
            (this.timer = setTimeout(() => {
                this.setState({
                    ...this.state,
                    show: false,
                });
            }, 15000));
    };
    componentWillUnmount = () => {
        this.props.autohide && clearTimeout(this.timer);
    };
    render() {
        return (
            this.state.show && (
                <div
                    {...this.props}
                    className={"notify " + (this.props.className || "")}
                >
                    {this.props.children}
                    {this.props.closebutton ? (
                        <Icon
                            type="fas"
                            className="fa-times"
                            onClick={(event) => {
                                this.setState({ ...this.state, show: false });
                            }}
                        ></Icon>
                    ) : (
                        <React.Fragment></React.Fragment>
                    )}
                </div>
            )
        );
    }
}

export default Notify;
