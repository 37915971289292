import Func from "@/app/comFunc/Func";
import React from "react";
import DiceResult from "../comDiceResult/DiceResult";
import "./dice_counter.scss";

class DiceCounter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            counter: null,
        };
        this.props.callback && this.props.callback();
    }

    componentDidMount() {
        this.running = setInterval(() => {
            this.setState({
                ...this.state,
                counter: Func.randomDice(),
            });
        }, 100);
    }

    componentWillUnmount = () => {
        clearInterval(this.running);
    };

    render() {
        return (
            this.state.counter && (
                <DiceResult
                    array={this.state.counter}
                    running={true}
                    big={this.props.big ? this.props.big : false}
                ></DiceResult>
            )
        );
    }
}

export default DiceCounter;
