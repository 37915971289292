import React from "react";
import { Link } from "react-router-dom";
import "./logo.scss";

class Logo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div
                {...this.props}
                className={"logo " + (this.props.class || "")}
                id={this.props.id || "logo"}
            >
                <Link to="/">
                    <img
                        src={
                            this.props.src
                                ? this.props.src
                                : "/images/defaults/vinfast.svg"
                        }
                        alt={this.props.alt ? this.props.alt : "Logo"}
                        title={this.props.title ? this.props.title : "Logo"}
                    />
                </Link>
            </div>
        );
    }
}

export default Logo;
