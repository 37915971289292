import React from "react";
import { Link } from "react-router-dom";
import "./image.scss";

class Image extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return this.props.link ? (
            <Link to={this.props.link || "/"} className="wrap__image">
                <img
                    {...this.props}
                    className={this.props.className || ""}
                    src={this.props.src || "/images/defaults/default.jpg"}
                    alt={this.props.alt}
                    title={this.props.title}
                />
            </Link>
        ) : (
            <Link
                to={this.props.link || "/"}
                className="wrap__image"
                onClick={(event) => event.preventDefault()}
            >
                <img
                    {...this.props}
                    className={this.props.className || ""}
                    src={this.props.src || "/images/defaults/default.jpg"}
                    alt={this.props.alt}
                    title={this.props.title}
                />
            </Link>
        );
    }
}

export default Image;
