import React from 'react';
import './fontawesome6/scss/fontawesome.scss';
import './fontawesome6/scss/solid.scss';
import './fontawesome6/scss/regular.scss';
import './fontawesome6/scss/light.scss';
import './fontawesome6/scss/brands.scss';

class Icon extends React.Component {
   constructor(props) {
      super(props);
      this.state = {};
   }
   render() {
      return <i {...this.props} className={(this.props.type || 'far') + ' ' + this.props.className}></i>;
   }
}

export default Icon;
