import Func from "@/app/comFunc/Func";
import React from "react";
import "./real_time.scss";

class RealTime extends React.Component {
    constructor(props) {
        super(props);
        window.RealTime = this;
        this.state = {};
        this.socket = window.Master.socket;
        this.timeOutStorage = null;
    }
    _isMounted = false;
    setStorage = (data) => {
        if (data) {
            Func.setStorage({
                balance: data.balance,
            });
            Func.notifySuccess(data.notify);
        }
        return;
    };
    componentDidMount() {
        this._isMounted = true;
        if (Func.env("REACT_APP_SOCKET_OPEN") === true && window.Master) {
            this.socket.on(Func.env("REACT_APP_NAME"), (result) => {
                let event = result.event,
                    data = result.data;
                switch (event) {
                    // case "global:cms.mACL.admin.index":
                    //     if (
                    //         window.AdminTable &&
                    //         window.AdminTable._isMounted === true &&
                    //         window.AdminTable.load
                    //     ) {
                    //         window.AdminTable.load(false);
                    //     }
                    //     break;
                    case Func.getStorage("id") + ":client.mACL.customer.notify":
                        this.timeOutStorage = setTimeout(
                            this.setStorage.bind(null, data),
                            1000
                        );
                        break;
                    case Func.getStorage("id") +
                        ":client.mACL.customerOdds.game_three_dice_ten_min":
                        if (
                            window.GameThreeDiceTenMin &&
                            window.GameThreeDiceTenMin._isMounted === true &&
                            window.GameThreeDiceTenMin.load
                        ) {
                            window.GameThreeDiceTenMin.load(false);
                        }
                        break;
                    case Func.getStorage("id") +
                        ":client.mACL.customerOdds.game_three_dice_five_min":
                        if (
                            window.GameThreeDiceFiveMin &&
                            window.GameThreeDiceFiveMin._isMounted === true &&
                            window.GameThreeDiceFiveMin.load
                        ) {
                            window.GameThreeDiceFiveMin.load(false);
                        }
                        break;
                    case Func.getStorage("id") +
                        ":client.mACL.customerOdds.game_three_dice_three_min":
                        if (
                            window.GameThreeDiceThreeMin &&
                            window.GameThreeDiceThreeMin._isMounted === true &&
                            window.GameThreeDiceThreeMin.load
                        ) {
                            window.GameThreeDiceThreeMin.load(false);
                        }
                        break;
                    case Func.getStorage("id") +
                        ":client.mACL.customerOdds.game_three_dice_one_min":
                        if (
                            window.GameThreeDiceOneMin &&
                            window.GameThreeDiceOneMin._isMounted === true &&
                            window.GameThreeDiceOneMin.load
                        ) {
                            window.GameThreeDiceOneMin.load(false);
                        }
                        break;
                    default:
                        break;
                }
            });
        }
    }
    componentWillUnmount = () => {
        this._isMounted = false;
        if (Func.env("REACT_APP_SOCKET_OPEN") === true && window.Master) {
            this.timeOutStorage && clearTimeout(this.timeOutStorage);
            this.socket.removeAllListeners();
        }
    };

    render() {
        return <React.Fragment></React.Fragment>;
    }
}

export default RealTime;
