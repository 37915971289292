import React from "react";
import "./dice_result.scss";

class DiceResult extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            counter1: null,
            counter2: null,
            counter3: null,
            pushBom1: false,
            pushBom2: false,
            pushBom3: false,
        };
    }

    componentDidMount() {
        this.running = setInterval(() => {
            this.setState({
                ...this.state,
                counter1: Math.floor(Math.random() * 10),
                counter2: Math.floor(Math.random() * 10),
                counter3: Math.floor(Math.random() * 10),
            });
        }, 100);
        if (this.props.effect) {
            this.pushBom3 = setTimeout(() => {
                this.setState({
                    ...this.state,
                    pushBom3: true,
                });
            }, 500);
            this.pushBom2 = setTimeout(() => {
                this.setState({
                    ...this.state,
                    pushBom2: true,
                });
            }, 250);
            this.pushBom1 = setTimeout(() => {
                this.setState({
                    ...this.state,
                    pushBom1: true,
                });
            }, 0);
        }
    }

    componentWillUnmount = () => {
        clearInterval(this.running);
        clearTimeout(this.pushBom3);
        clearTimeout(this.pushBom2);
        clearTimeout(this.pushBom1);
    };

    render() {
        return !this.props.array || !(this.props.array instanceof Array) ? (
            <div
                className={
                    "dice-result" +
                    (this.props.big === true ? " dice-result__big" : "")
                }
            >
                <div>
                    {["?", "?", "?"].map((item, index) => {
                        return (
                            <span
                                className={
                                    "dice-result-empty" +
                                    (this.props.running === true
                                        ? " dice-result__running"
                                        : "") +
                                    (this.props.final === true
                                        ? " dice-result__final"
                                        : "")
                                }
                                key={index}
                            >
                                {item}
                            </span>
                        );
                    })}
                </div>
            </div>
        ) : (
            <div
                className={
                    "dice-result" +
                    (this.props.big === true ? " dice-result__big" : "")
                }
            >
                <div>
                    {this.props.effect ? (
                        <React.Fragment>
                            {this.state.pushBom1 ? (
                                <span
                                    className={
                                        "dice-result__final dice-result__" +
                                        this.props.array[0]
                                    }
                                ></span>
                            ) : (
                                <span
                                    className={
                                        "dice-result__" + this.state.counter1
                                    }
                                ></span>
                            )}
                            {this.state.pushBom2 ? (
                                <span
                                    className={
                                        "dice-result__final dice-result__" +
                                        this.props.array[1]
                                    }
                                ></span>
                            ) : (
                                <span
                                    className={
                                        "dice-result__" + this.state.counter2
                                    }
                                ></span>
                            )}
                            {this.state.pushBom3 ? (
                                <span
                                    className={
                                        "dice-result__final dice-result__" +
                                        this.props.array[2]
                                    }
                                ></span>
                            ) : (
                                <span
                                    className={
                                        "dice-result__" + this.state.counter3
                                    }
                                ></span>
                            )}
                        </React.Fragment>
                    ) : (
                        this.props.array.map((item, index) => {
                            return (
                                <span
                                    className={
                                        "dice-result__" +
                                        item +
                                        (this.props.running === true
                                            ? " dice-result__running"
                                            : "") +
                                        (this.props.final === true
                                            ? " dice-result__final"
                                            : "")
                                    }
                                    key={index}
                                ></span>
                            );
                        })
                    )}
                </div>
            </div>
        );
    }
}

export default DiceResult;
