import React from "react";
import "./password_withdraw.scss";
import withRouter from "@/app/comRouter/withRouter";
import Form from "@/components/comForm/Form";
import Input from "@/components/comForm/comInput/Input";
import Func from "@/app/comFunc/Func";
import Icon from "@/components/comIcon/Icon";
import Button from "@/components/comButton/Button";
import Sending from "@/components/comSending/Sending";
import Validator from "@/app/comValidator/Validator";
import { Navigate } from "react-router-dom";
import Header from "@/layouts/comHeader/Header";
import { PageSlide, PageWelcome } from "../../comHome/Home";
import Partner from "@/screens/comAuth/Partner";

const rules = [
    {
        field: "password_withdraw",
        method: "isEmpty",
        validWhen: false,
        message: "Mật khẩu rút tiền cũ không được bỏ trống.",
    },
    {
        field: "password_withdraw_new",
        method: "isEmpty",
        validWhen: false,
        message: "Mật khẩu rút tiền mới không được bỏ trống.",
    },
    {
        field: "password_withdraw_new",
        method: "isLength",
        args: [{ min: 3, max: 100 }],
        validWhen: true,
        message: "Mật khẩu rút tiền mới từ 3 tới 100 ký tự.",
    },
];

class PasswordWithdraw extends React.Component {
    constructor(props) {
        super(props);
        window.PasswordWithdraw = this;
        this.state = {
            is_sending: Func.getStorage("is_sending"),
            password_withdraw: null,
            password_withdraw_new: null,
            show_password_withdraw: false,
            show_password_withdraw_new: false,
            submited: false,
            errors: {},
        };
        this.validator = new Validator(rules);
    }
    componentDidMount = () => {
        this._isMounted = true;
    };
    _isMounted = false;
    componentWillUnmount = () => {
        this._isMounted = false;
    };
    handleShowPasswordWithdraw = (event) => {
        let checkClass = event.target.classList.contains("fa-eye");
        this.setState({
            ...this.state,
            show_password_withdraw: checkClass ? true : false,
        });
    };
    handleShowPasswordWithdrawNew = (event) => {
        let checkClass = event.target.classList.contains("fa-eye");
        this.setState({
            ...this.state,
            show_password_withdraw_new: checkClass ? true : false,
        });
    };

    handleSubmit = (event) => {
        event.preventDefault();
        this.setState(
            {
                ...this.state,
                errors: this.validator.valid(this.state),
            },
            () => {
                if (Func.checkObjectEmpty(this.state.errors)) {
                    Func.send(
                        Func.getApi(
                            "client.mACL.customer.change_password_withdraw",
                            "path",
                            {
                                customer: Func.getStorage("id"),
                            }
                        ),
                        Func.getApi(
                            "client.mACL.customer.change_password_withdraw",
                            "method"
                        ),
                        {
                            password_withdraw: this.state.password_withdraw,
                            password_withdraw_new:
                                this.state.password_withdraw_new,
                        },
                        (res) => {
                            res.data.message &&
                                Func.notifySuccess(res.data.message);
                            this._isMounted &&
                                this.setState({
                                    submited: true,
                                });
                        },
                        (res) => {
                            Func.handleErrors(res, this);
                        }
                    );
                }
            }
        );
    };

    render() {
        return this.state.submited ? (
            <Navigate to={Func.getRoute("account")}></Navigate>
        ) : (
            <React.Fragment>
                <Header
                    heading={Func.getRoute("account.password_withdraw", "text")}
                ></Header>
                <div id="auth" className="auth">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <PageSlide></PageSlide>
                                <PageWelcome></PageWelcome>
                                <Form
                                    onSubmit={this.handleSubmit}
                                    id="auth__form"
                                    className="auth__form"
                                >
                                    <div className="auth__form-content">
                                        <div className="auth__form-item">
                                            <div className="auth__form-icon">
                                                <Input
                                                    onInput={(event) =>
                                                        Func.setValueInput(
                                                            event,
                                                            this
                                                        )
                                                    }
                                                    type={
                                                        this.state
                                                            .show_password_withdraw
                                                            ? "text"
                                                            : "password"
                                                    }
                                                    name="password_withdraw"
                                                    autoComplete="new-password"
                                                    className={
                                                        this.state.errors
                                                            .password_withdraw &&
                                                        "validator__error"
                                                    }
                                                    placeholder="Mật khẩu rút tiền cũ (bắt buộc)"
                                                    readOnly={
                                                        this.state.is_sending
                                                    }
                                                    value={
                                                        this.state
                                                            .password_withdraw
                                                    }
                                                ></Input>
                                                <Icon
                                                    type="fas"
                                                    onClick={
                                                        this
                                                            .handleShowPasswordWithdraw
                                                    }
                                                    className={
                                                        this.state
                                                            .show_password_withdraw
                                                            ? "fa-eye-slash"
                                                            : "fa-eye"
                                                    }
                                                ></Icon>
                                            </div>
                                            {this.state.errors
                                                .password_withdraw && (
                                                <div className="validator__error">
                                                    {
                                                        this.state.errors
                                                            .password_withdraw
                                                    }
                                                </div>
                                            )}
                                        </div>
                                        <div className="auth__form-item">
                                            <div className="auth__form-icon">
                                                <Input
                                                    onInput={(event) =>
                                                        Func.setValueInput(
                                                            event,
                                                            this
                                                        )
                                                    }
                                                    type={
                                                        this.state
                                                            .show_password_withdraw_new
                                                            ? "text"
                                                            : "password"
                                                    }
                                                    name="password_withdraw_new"
                                                    autoComplete="new-password"
                                                    className={
                                                        this.state.errors
                                                            .password_withdraw_new &&
                                                        "validator__error"
                                                    }
                                                    placeholder="Mật khẩu rút tiền mới (bắt buộc)"
                                                    readOnly={
                                                        this.state.is_sending
                                                    }
                                                    value={
                                                        this.state
                                                            .password_withdraw_new
                                                    }
                                                ></Input>
                                                <Icon
                                                    type="fas"
                                                    onClick={
                                                        this
                                                            .handleShowPasswordWithdrawNew
                                                    }
                                                    className={
                                                        this.state
                                                            .show_password_withdraw_new
                                                            ? "fa-eye-slash"
                                                            : "fa-eye"
                                                    }
                                                ></Icon>
                                            </div>
                                            {this.state.errors
                                                .password_withdraw_new && (
                                                <div className="validator__error">
                                                    {
                                                        this.state.errors
                                                            .password_withdraw_new
                                                    }
                                                </div>
                                            )}
                                        </div>
                                        <div className="auth__form-item">
                                            <Button
                                                disabled={this.state.is_sending}
                                                type="submit"
                                                className="auth__form-button btn btn__warning btn__big"
                                                id="auth__form-button"
                                            >
                                                {this.state.is_sending ? (
                                                    <Sending></Sending>
                                                ) : (
                                                    "Cập nhật"
                                                )}
                                            </Button>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                    <Partner></Partner>
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(React.memo(PasswordWithdraw));
