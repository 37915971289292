import React from "react";
import "./withdraw.scss";
import withRouter from "@/app/comRouter/withRouter";
import Header from "@/layouts/comHeader/Header";
import Button from "@/components/comButton/Button";
import Partner from "@/screens/comAuth/Partner";
import { Navigate } from "react-router";
import { PageSlide, PageWelcome } from "@/screens/comHome/Home";
import Form from "@/components/comForm/Form";
import Notify from "@/components/comNotify/Notify";
import Input from "@/components/comForm/comInput/Input";
import Icon from "@/components/comIcon/Icon";
import Func from "@/app/comFunc/Func";
import Const from "@/app/comConst/Const";
import Validator from "@/app/comValidator/Validator";

const rules = [
    {
        field: "password_withdraw",
        method: "isLength",
        args: [{ min: 3, max: 100 }],
        validWhen: true,
        message: "Mật khẩu rút tiền từ 3 tới 100 ký tự.",
    },
];

class Withdraw extends React.Component {
    constructor(props) {
        super(props);
        window.Withdraw = this;
        this.state = {
            submited: false,
            balance: null,
            bankId: null,
            bankName: null,
            bankNumber: null,
            bankUser: null,
            userBalance: null,
            password_withdraw: null,
            password_withdraw_verify: null,
            check_password_withdraw: false,
            errors: {},
            show_password_withdraw: false,
            isSending: false,
        };
        this.validator = new Validator(rules);
    }
    _isMounted = false;
    componentDidMount = () => {
        this._isMounted = true;
        this.load();
    };
    componentWillUnmount() {
        this._isMounted = false;
    }
    handleShowPasswordWithdraw = (event) => {
        let checkClass = event.target.classList.contains("fa-eye");
        this.setState({
            ...this.state,
            show_password_withdraw: checkClass ? true : false,
        });
    };
    handleShowPasswordWithdrawVerify = (event) => {
        let checkClass = event.target.classList.contains("fa-eye");
        this.setState({
            ...this.state,
            show_password_withdraw_verify: checkClass ? true : false,
        });
    };
    load = () => {
        this._isMounted &&
            this.setState({
                ...this.state,
                isSending: true,
            });
        Func.send(
            Func.getApi("client.mACL.withdraw.create", "path"),
            Func.getApi("client.mACL.withdraw.create", "method"),
            null,
            (res) => {
                if (res.data && res.data.data) {
                    let data = res.data.data;
                    this._isMounted &&
                        this.setState({
                            ...this.state,
                            bankId: data.bank_id,
                            bankName: data.bank_name,
                            bankNumber: data.bank_number,
                            bankUser: data.bank_user,
                            userBalance: data.balance,
                            check_password_withdraw:
                                data.check_password_withdraw,
                        });
                    Func.setUserStorage(data);
                }
            },
            (res) => {}
        );
    };
    onSubmitWithdraw = (event) => {
        event.preventDefault();
        if (this.state.balance && Func.filterNumeric(this.state.balance) > 0) {
            Func.confirmAlert(
                "Xác nhận gửi yêu cầu rút tiền ? <br>Tài khoản của quý khách sẽ bị trừ số tiền rút tương ứng ngay lập tức.",
                (confirmed) => {
                    if (confirmed) {
                        Func.send(
                            Func.getApi("client.mACL.withdraw.store", "path"),
                            Func.getApi("client.mACL.withdraw.store", "method"),
                            {
                                balance: this.state.balance,
                                password_withdraw: this.state.password_withdraw,
                            },
                            (res) => {
                                if (res.data.code === 200) {
                                    res.data.message &&
                                        Func.notifySuccess(res.data.message);
                                    this._isMounted &&
                                        this.setState({
                                            bankId: null,
                                            bankName: null,
                                            bankNumber: null,
                                            bankUser: null,
                                            userBalance: null,
                                            submited: true,
                                        });
                                } else {
                                    Func.handleErrors(res, this);
                                }
                            },
                            (res) => {
                                Func.handleErrors(res, this);
                            }
                        );
                    }
                }
            );
        }
    };
    onSubmitPassword = (event) => {
        event.preventDefault();
        this.setState(
            {
                ...this.state,
                errors: this.validator.valid(this.state),
            },
            () => {
                if (Func.checkObjectEmpty(this.state.errors)) {
                    Func.send(
                        Func.getApi(
                            "client.mACL.customer.password_withdraw",
                            "path",
                            { customer: Func.getStorage("id") }
                        ),
                        Func.getApi(
                            "client.mACL.customer.password_withdraw",
                            "method"
                        ),
                        {
                            password_withdraw: this.state.password_withdraw,
                        },
                        (res) => {
                            res.data.message &&
                                Func.notifySuccess(res.data.message);
                            this._isMounted &&
                                this.setState({
                                    password_withdraw: res.data.data,
                                    check_password_withdraw: true,
                                });
                        },
                        (res) => {
                            Func.handleErrors(res, this);
                        }
                    );
                }
            }
        );
    };
    onSubmitCheck = (event) => {
        event.preventDefault();
        Func.send(
            Func.getApi(
                "client.mACL.customer.check_password_withdraw",
                "path",
                {
                    customer: Func.getStorage("id"),
                }
            ),
            Func.getApi(
                "client.mACL.customer.check_password_withdraw",
                "method"
            ),
            {
                password_withdraw: this.state.password_withdraw_verify,
            },
            (res) => {
                this._isMounted &&
                    this.setState({
                        password_withdraw: res.data.data,
                        check_password_withdraw: true,
                    });
            },
            (res) => {
                Func.handleErrors(res, this);
            }
        );
    };
    handleChips = (event, money) => {
        event.preventDefault();
        if (
            money &&
            this.state.bankId &&
            this.state.bankName &&
            this.state.bankUser &&
            this.state.bankNumber
        ) {
            let balance = this.state.balance;
            if (balance) {
                money = Func.filterNumeric(money) + Func.filterNumeric(balance);
            }
            this._isMounted &&
                this.setState({
                    balance:
                        money <= Func.filterNumeric(this.state.userBalance)
                            ? money
                            : Func.filterNumeric(this.state.userBalance),
                });
            return true;
        }
        return false;
    };
    render() {
        return this.state.submited ? (
            <Navigate to={Func.getRoute("account.withdraw.history")}></Navigate>
        ) : (
            <React.Fragment>
                <Header
                    heading={Func.getRoute("account.withdraw", "text")}
                ></Header>
                <div className="withdraw">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <PageSlide></PageSlide>
                                <PageWelcome></PageWelcome>
                                {this.state.check_password_withdraw ? (
                                    <React.Fragment>
                                        {this.state.password_withdraw ? (
                                            <Form
                                                onSubmit={this.onSubmitWithdraw}
                                                id="form"
                                                className="form"
                                            >
                                                <div className="form__item">
                                                    <div className="form__item-title">
                                                        Nhập số tiền rút
                                                        <span className="form__item-required">
                                                            *
                                                        </span>
                                                        {/* <span>
                                                            (Tối đa:{" "}
                                                            {Func.formatBalance(
                                                                this.state
                                                                    .userBalance
                                                            )}
                                                            )
                                                        </span> */}
                                                    </div>
                                                    <div className="form__item-field">
                                                        <Input
                                                            format="float"
                                                            decimal={2}
                                                            max={
                                                                this.state
                                                                    .userBalance
                                                                    ? this.state
                                                                          .userBalance
                                                                    : false
                                                            }
                                                            onInput={(event) =>
                                                                Func.setValueInput(
                                                                    event,
                                                                    this
                                                                )
                                                            }
                                                            suffix={" $"}
                                                            name="balance"
                                                            placeholder="Nhập số tiền..."
                                                            value={
                                                                this.state
                                                                    .balance
                                                            }
                                                            disabled={
                                                                this.state
                                                                    .bankUser &&
                                                                this.state
                                                                    .bankNumber &&
                                                                this.state
                                                                    .bankNumber
                                                                    ? false
                                                                    : true
                                                            }
                                                            readOnly={
                                                                this.state
                                                                    .bankUser &&
                                                                this.state
                                                                    .bankNumber &&
                                                                this.state
                                                                    .bankNumber
                                                                    ? false
                                                                    : true
                                                            }
                                                        ></Input>
                                                        <Icon
                                                            type="fas"
                                                            onClick={() =>
                                                                this.state
                                                                    .balance &&
                                                                this
                                                                    ._isMounted &&
                                                                this.setState({
                                                                    balance:
                                                                        null,
                                                                })
                                                            }
                                                            className={
                                                                this.state
                                                                    .balance &&
                                                                Func.filterNumeric(
                                                                    this.state
                                                                        .balance
                                                                ) > 0
                                                                    ? "fa-eraser"
                                                                    : "fa-keyboard"
                                                            }
                                                        ></Icon>
                                                    </div>
                                                    <div className="form__item-field">
                                                        <div
                                                            className={
                                                                "chips" +
                                                                (this.state
                                                                    .bankUser &&
                                                                this.state
                                                                    .bankNumber &&
                                                                this.state
                                                                    .bankNumber
                                                                    ? ""
                                                                    : " disabled")
                                                            }
                                                        >
                                                            {Const.chips.map(
                                                                (chip) => {
                                                                    return (
                                                                        <div
                                                                            key={
                                                                                "chip_" +
                                                                                chip.key
                                                                            }
                                                                            className={
                                                                                "chips__item chips__item-" +
                                                                                chip.key
                                                                            }
                                                                            onClick={(
                                                                                event
                                                                            ) =>
                                                                                this.handleChips(
                                                                                    event,
                                                                                    chip.value
                                                                                )
                                                                            }
                                                                        >
                                                                            {
                                                                                chip.name
                                                                            }
                                                                        </div>
                                                                    );
                                                                }
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                {this.state.bankUser &&
                                                this.state.bankId &&
                                                this.state.bankName &&
                                                this.state.bankNumber ? (
                                                    <React.Fragment>
                                                        <div className="form__item">
                                                            <div className="bank-info">
                                                                <div className="bank-info__title fw__bold">
                                                                    Tài khoản
                                                                    ngân hàng
                                                                    thụ hưởng:
                                                                </div>
                                                                <div className="bank-info__item">
                                                                    <p>
                                                                        Ngân
                                                                        hàng:
                                                                    </p>
                                                                    <strong>
                                                                        {
                                                                            this
                                                                                .state
                                                                                .bankName
                                                                        }
                                                                    </strong>
                                                                </div>
                                                                <div className="bank-info__item">
                                                                    <p>
                                                                        Chủ tài
                                                                        khoản:
                                                                    </p>
                                                                    <strong>
                                                                        {
                                                                            this
                                                                                .state
                                                                                .bankUser
                                                                        }
                                                                    </strong>
                                                                </div>
                                                                <div className="bank-info__item">
                                                                    <p>
                                                                        Số tài
                                                                        khoản:
                                                                    </p>
                                                                    <strong>
                                                                        {
                                                                            this
                                                                                .state
                                                                                .bankNumber
                                                                        }
                                                                    </strong>
                                                                </div>
                                                                <div className="bank-info__item">
                                                                    <p>
                                                                        Số tiền
                                                                        rút:
                                                                    </p>
                                                                    <strong>
                                                                        {Func.formatBalance(
                                                                            this
                                                                                .state
                                                                                .balance
                                                                        )}
                                                                    </strong>
                                                                </div>
                                                                <div className="bank-info__note">
                                                                    *Số tiền có
                                                                    thể rút
                                                                    (CƯỢC HỢP
                                                                    LỆ) tài
                                                                    khoản cần cá
                                                                    cược 80% số
                                                                    tiền nạp mới
                                                                    được rút
                                                                    tiền. <br />
                                                                    *MIN rút 10
                                                                    USD, MAX rút
                                                                    100,000,000
                                                                    USD <br />
                                                                    *Mỗi ngày
                                                                    tối thiểu
                                                                    rút tiền 5
                                                                    lần <br />
                                                                    *Chi tiết
                                                                    vui lòng
                                                                    liên hệ bộ
                                                                    phận CSKH
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form__item">
                                                            <Button
                                                                className="btn btn__big"
                                                                type="submit"
                                                                disabled={
                                                                    this.state
                                                                        .balance &&
                                                                    Func.filterNumeric(
                                                                        this
                                                                            .state
                                                                            .balance
                                                                    ) >= 10 &&
                                                                    this.state
                                                                        .bankId
                                                                        ? false
                                                                        : true
                                                                }
                                                                readOnly={
                                                                    this.state
                                                                        .balance &&
                                                                    Func.filterNumeric(
                                                                        this
                                                                            .state
                                                                            .balance
                                                                    ) >= 10 &&
                                                                    this.state
                                                                        .bankId
                                                                        ? false
                                                                        : true
                                                                }
                                                            >
                                                                Gửi yêu cầu rút
                                                                tiền
                                                            </Button>
                                                        </div>
                                                    </React.Fragment>
                                                ) : (
                                                    <React.Fragment>
                                                        <Notify
                                                            className="notify__default"
                                                            style={{
                                                                marginBottom:
                                                                    "0px",
                                                            }}
                                                        >
                                                            Quý khách chưa cập
                                                            nhật đầy đủ thông
                                                            tin ngân hàng !{" "}
                                                            <br />
                                                            Vui lòng cập nhật
                                                            đẩy đủ để tiến hành
                                                            rút tiền.
                                                            <br />
                                                        </Notify>
                                                    </React.Fragment>
                                                )}
                                            </Form>
                                        ) : (
                                            <Form
                                                onSubmit={this.onSubmitCheck}
                                                id="form"
                                                className="form"
                                            >
                                                <div className="form__item">
                                                    <div className="form__item-title">
                                                        Vui lòng nhập mật khẩu
                                                        rút tiền để tiếp tục:
                                                        <span className="form__item-required">
                                                            *
                                                        </span>
                                                    </div>
                                                    <div className="form__item-field">
                                                        <Input
                                                            onInput={(event) =>
                                                                Func.setValueInput(
                                                                    event,
                                                                    this
                                                                )
                                                            }
                                                            type={
                                                                this.state
                                                                    .show_password_withdraw_verify
                                                                    ? "text"
                                                                    : "password"
                                                            }
                                                            name="password_withdraw_verify"
                                                            autoComplete="new-password"
                                                            className={
                                                                this.state
                                                                    .errors
                                                                    .password_withdraw_verify &&
                                                                "validator__error"
                                                            }
                                                            placeholder="Mật khẩu rút tiền (bắt buộc)"
                                                            readOnly={
                                                                this.state
                                                                    .is_sending
                                                            }
                                                            value={
                                                                this.state
                                                                    .password_withdraw_verify
                                                            }
                                                        ></Input>
                                                        <Icon
                                                            type="fas"
                                                            onClick={
                                                                this
                                                                    .handleShowPasswordWithdrawVerify
                                                            }
                                                            className={
                                                                this.state
                                                                    .show_password_withdraw_verify
                                                                    ? "fa-eye-slash"
                                                                    : "fa-eye"
                                                            }
                                                        ></Icon>
                                                    </div>
                                                    {this.state.errors
                                                        .password_withdraw_verify && (
                                                        <div className="validator__error">
                                                            {
                                                                this.state
                                                                    .errors
                                                                    .password_withdraw_verify
                                                            }
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="form__item">
                                                    <Button
                                                        className="btn btn__big"
                                                        type="submit"
                                                    >
                                                        Xác thực
                                                    </Button>
                                                </div>
                                            </Form>
                                        )}
                                    </React.Fragment>
                                ) : (
                                    <Form
                                        onSubmit={this.onSubmitPassword}
                                        id="form"
                                        className="form"
                                    >
                                        <div className="form__item">
                                            <Notify
                                                className="notify__warning"
                                                style={{ marginBottom: "0px" }}
                                            >
                                                Hãy tạo mật khẩu rút tiền để bảo
                                                vệ số dư tài khoản không bị đánh
                                                cắp.
                                                <br />
                                            </Notify>
                                        </div>
                                        <div className="form__item">
                                            <div className="form__item-title">
                                                Mật khẩu rút tiền:
                                                <span className="form__item-required">
                                                    *
                                                </span>
                                            </div>
                                            <div className="form__item-field">
                                                <Input
                                                    max={100}
                                                    onInput={(event) =>
                                                        Func.setValueInput(
                                                            event,
                                                            this
                                                        )
                                                    }
                                                    type={
                                                        this.state
                                                            .show_password_withdraw
                                                            ? "text"
                                                            : "password"
                                                    }
                                                    name="password_withdraw"
                                                    autoComplete="new-password"
                                                    className={
                                                        this.state.errors
                                                            .password_withdraw &&
                                                        "validator__error"
                                                    }
                                                    placeholder="Mật khẩu rút tiền (bắt buộc)"
                                                    readOnly={
                                                        this.state.is_sending
                                                    }
                                                    value={
                                                        this.state
                                                            .password_withdraw
                                                    }
                                                ></Input>
                                                <Icon
                                                    type="fas"
                                                    onClick={
                                                        this
                                                            .handleShowPasswordWithdraw
                                                    }
                                                    className={
                                                        this.state
                                                            .show_password_withdraw
                                                            ? "fa-eye-slash"
                                                            : "fa-eye"
                                                    }
                                                ></Icon>
                                            </div>
                                            {this.state.errors
                                                .password_withdraw && (
                                                <div className="validator__error">
                                                    {
                                                        this.state.errors
                                                            .password_withdraw
                                                    }
                                                </div>
                                            )}
                                        </div>
                                        <div className="form__item">
                                            <Button
                                                className="btn btn__big"
                                                type="submit"
                                            >
                                                Cập nhật mật khẩu
                                            </Button>
                                        </div>
                                    </Form>
                                )}
                            </div>
                        </div>
                    </div>
                    <Partner></Partner>
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(React.memo(Withdraw));
