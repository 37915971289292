import React from "react";
import "./auth.scss";
import withRouter from "@/app/comRouter/withRouter";
import Form from "@/components/comForm/Form";
import Input from "@/components/comForm/comInput/Input";
import Func from "@/app/comFunc/Func";
import Icon from "@/components/comIcon/Icon";
import Button from "@/components/comButton/Button";
import Sending from "@/components/comSending/Sending";
import Validator from "@/app/comValidator/Validator";
import { Link, Navigate } from "react-router-dom";
import Header from "@/layouts/comHeader/Header";
import Partner from "./Partner";
import { PageSlide, PageWelcome } from "../comHome/Home";
const rules = [
    {
        field: "user",
        method: "isEmpty",
        validWhen: false,
        message: "Tên đăng nhập không được bỏ trống.",
    },
    {
        field: "password",
        method: "isEmpty",
        validWhen: false,
        message: "Mật khẩu không được bỏ trống.",
    },
];

class Login extends React.Component {
    constructor(props) {
        super(props);
        window.Login = this;
        this.state = {
            is_login: false,
            is_sending: Func.getStorage("is_sending"),
            user: Func.getParamUrl("user") || null,
            password: null,
            show_password: false,
            errors: {},
        };
        this.validator = new Validator(rules);
    }
    _isMounted = false;
    componentDidMount = () => {
        this._isMounted = true;
        this.interval = setInterval(this.check, 1);
    };
    componentWillUnmount = () => {
        this._isMounted = false;
        clearInterval(this.interval);
    };
    check = () => {
        if (
            Func.getStorage("token") &&
            Func.getStorage("token_expired") === false
        ) {
            this.state.is_login !== true &&
                this._isMounted &&
                this.setState({
                    ...this.state,
                    is_login: true,
                });
            return true;
        } else {
            this.state.is_login !== false &&
                this._isMounted &&
                this.setState({
                    ...this.state,
                    is_login: false,
                });
            return false;
        }
    };
    handleIsSending = (value) => {
        this._isMounted &&
            this.setState({
                ...this.state,
                is_sending: value ? true : false,
            });
    };
    handleSubmit = (event) => {
        event.preventDefault();
        this._isMounted &&
            this.setState(
                {
                    ...this.state,
                    errors: this.validator.valid(this.state),
                },
                () => {
                    if (Func.checkObjectEmpty(this.state.errors)) {
                        this.handleIsSending(true);
                        Func.send(
                            Func.getApi("auth.login"),
                            Func.getApi("auth.login", "method"),
                            {
                                user: this.state.user,
                                password: this.state.password,
                            },
                            (res) => {
                                if (res.data.data) {
                                    let data = res.data.data;
                                    Func.setUserStorage(data);
                                } else {
                                    Func.handleErrors(res, this);
                                }
                                this.handleIsSending(false);
                            },
                            (res) => {
                                Func.handleErrors(res, this);
                                this.handleIsSending(false);
                            }
                        );
                    }
                }
            );
    };

    handleShowPassword = (event) => {
        let checkClass = event.target.classList.contains("fa-eye");
        this._isMounted &&
            this.setState({
                ...this.state,
                show_password: checkClass ? true : false,
            });
    };
    render() {
        return this.state.is_login ? (
            <Navigate to={Func.getRoute("home")}></Navigate>
        ) : (
            <React.Fragment>
                <Header heading={Func.getRoute("login", "text")}></Header>
                <div id="auth" className="auth">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <PageSlide></PageSlide>
                                <PageWelcome></PageWelcome>
                                <Form
                                    onSubmit={this.handleSubmit}
                                    id="auth__form"
                                    className="auth__form"
                                >
                                    <div className="auth__form-content">
                                        <div className="auth__form-item">
                                            <Input
                                                format="alphadash"
                                                onInput={(event) =>
                                                    Func.setValueInput(
                                                        event,
                                                        this
                                                    )
                                                }
                                                name="user"
                                                className={
                                                    "auth__form-user " +
                                                    (this.state.errors.user
                                                        ? "validator__error"
                                                        : "")
                                                }
                                                id="auth__form-user"
                                                placeholder="Tên đăng nhập (bắt buộc)"
                                                readOnly={this.state.is_sending}
                                                value={this.state.user}
                                            ></Input>
                                            {this.state.errors.user && (
                                                <div className="validator__error">
                                                    {this.state.errors.user}
                                                </div>
                                            )}
                                        </div>
                                        <div className="auth__form-item">
                                            <div className="auth__form-icon">
                                                <Input
                                                    onInput={(event) =>
                                                        Func.setValueInput(
                                                            event,
                                                            this
                                                        )
                                                    }
                                                    type={
                                                        this.state.show_password
                                                            ? "text"
                                                            : "password"
                                                    }
                                                    name="password"
                                                    className={
                                                        "auth__form-password " +
                                                        (this.state.errors
                                                            .password
                                                            ? "validator__error"
                                                            : "")
                                                    }
                                                    id="auth__form-password"
                                                    placeholder="Mật khẩu (bắt buộc)"
                                                    readOnly={
                                                        this.state.is_sending
                                                    }
                                                    value={this.state.password}
                                                ></Input>
                                                <Icon
                                                    type="fas"
                                                    onClick={
                                                        this.handleShowPassword
                                                    }
                                                    className={
                                                        this.state.show_password
                                                            ? "fa-eye-slash"
                                                            : "fa-eye"
                                                    }
                                                ></Icon>
                                            </div>
                                            {this.state.errors.password && (
                                                <div className="validator__error">
                                                    {this.state.errors.password}
                                                </div>
                                            )}
                                        </div>
                                        <div className="auth__form-item">
                                            <Button
                                                disabled={this.state.is_sending}
                                                type="submit"
                                                className="auth__form-button btn btn__big"
                                                id="auth__form-button"
                                            >
                                                {this.state.is_sending ? (
                                                    <Sending></Sending>
                                                ) : (
                                                    <React.Fragment>
                                                        <span>Đăng nhập</span>
                                                        <Icon
                                                            type="fas"
                                                            className="fa-right-to-bracket"
                                                        ></Icon>
                                                    </React.Fragment>
                                                )}
                                            </Button>
                                        </div>
                                    </div>
                                    <div className="auth__form-footer">
                                        <span>Chưa có tài khoản ?</span>
                                        <Link to={Func.getRoute("register")}>
                                            Đăng ký ngay
                                        </Link>
                                    </div>
                                    <Partner></Partner>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(React.memo(Login));
