/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import "./account.scss";
import withRouter from "@/app/comRouter/withRouter";
import Header from "@/layouts/comHeader/Header";
import Func from "@/app/comFunc/Func";
import { Navigate, NavLink } from "react-router-dom";
import Icon from "@/components/comIcon/Icon";
import Partner from "../comAuth/Partner";
import Image from "@/components/comImage/Image";

class Account extends React.Component {
    constructor(props) {
        super(props);
        window.Account = this;
        this.state = {
            is_login: false,
            submited: false,
            username: Func.getStorage("username"),
            balance: Func.getStorage("balance"),
            image: Func.getStorage("image"),
            code: Func.getStorage("code"),
            id: Func.getStorage("id"),
            vip: Func.getStorage("vip"),
            created_at: null,
            check_password_withdraw: false,
        };
    }
    _isMounted = false;
    componentDidMount = () => {
        this._isMounted = true;
        this.interval = setInterval(this.check, 1);
        this.load();
    };
    componentWillUnmount = () => {
        this._isMounted = false;
        clearInterval(this.interval);
    };
    check = () => {
        if (Func.getStorage("username") !== this.state.username) {
            this._isMounted &&
                this.setState({
                    ...this.state,
                    username: Func.getStorage("username"),
                });
        }
        if (Func.getStorage("balance") !== this.state.balance) {
            this._isMounted &&
                this.setState({
                    ...this.state,
                    balance: Func.getStorage("balance"),
                });
        }
        if (Func.getStorage("image") !== this.state.image) {
            this._isMounted &&
                this.setState({
                    ...this.state,
                    image: Func.getStorage("image"),
                });
        }
        if (Func.getStorage("code") !== this.state.code) {
            this._isMounted &&
                this.setState({
                    ...this.state,
                    code: Func.getStorage("code"),
                });
        }
        if (Func.getStorage("id") !== this.state.id) {
            this._isMounted &&
                this.setState({
                    ...this.state,
                    id: Func.getStorage("id"),
                });
        }
        if (Func.getStorage("vip") !== this.state.vip) {
            this._isMounted &&
                this.setState({
                    ...this.state,
                    vip: Func.getStorage("vip"),
                });
        }
        if (
            Func.getStorage("token") &&
            Func.getStorage("token_expired") === false
        ) {
            this.state.is_login !== true &&
                this._isMounted &&
                this.setState({
                    ...this.state,
                    is_login: true,
                });
            return true;
        } else {
            this.state.is_login !== false &&
                this._isMounted &&
                this.setState({
                    ...this.state,
                    is_login: false,
                });
            return false;
        }
    };
    load = () => {
        this.state.id &&
            Func.send(
                Func.getApi("client.mACL.customer.info", "path", {
                    customer: this.state.id,
                }),
                Func.getApi("client.mACL.customer.info", "method"),
                null,
                (res) => {
                    if (res.data && res.data.data) {
                        let data = res.data.data;
                        Func.setUserStorage(data);
                        this._isMounted &&
                            this.setState({
                                ...this.state,
                                username: data.username,
                                balance: data.balance,
                                image: data.image,
                                code: data.code,
                                vip: data.vip,
                                id: data._id,
                                created_at: data.created_at,
                                check_password_withdraw:
                                    data.check_password_withdraw,
                            });
                    }
                },
                (res) => {
                    this._isMounted &&
                        this.setState({
                            ...this.state,
                            submited: true,
                        });
                    console.clear();
                }
            );
    };
    handleLogout = () => {
        Func.confirmAlert("Bạn có thực sự muốn đăng xuất ? ", (confirmed) => {
            if (confirmed) {
                Func.send(
                    Func.getApi("auth.logout"),
                    Func.getApi("auth.logout", "method"),
                    null,
                    (res) => {
                        if (res.data.data) {
                            Func.setStorage({
                                token: res.data.data.token,
                                token_expired: res.data.data.token_expired,
                            });
                        } else {
                            Func.setStorage({
                                token_expired: true,
                            });
                            Func.handleErrors(res, this);
                        }
                    },
                    (res) => {
                        Func.setStorage({
                            token_expired: true,
                        });
                        Func.handleErrors(res, this);
                    }
                );
            }
        });
    };
    render() {
        return this.state.submited ? (
            <Navigate to={-1}></Navigate>
        ) : (
            <React.Fragment>
                <Header heading={Func.getRoute("account", "text")}></Header>
                <div className="account">
                    <div className="account__user">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="account__user-container">
                                        <div className="account__user-info">
                                            <img
                                                src={
                                                    this.state.image
                                                        ? Func.getImage(
                                                              this.state.image
                                                          )
                                                        : Func.getAvatarDefault()
                                                }
                                                alt=""
                                            />
                                            <div>
                                                <strong>
                                                    <Icon
                                                        type="fas"
                                                        className="fa-user-check"
                                                    ></Icon>
                                                    {this.state.username
                                                        ? this.state.username
                                                        : "..."}
                                                    <Icon
                                                        type="far"
                                                        className="fa-copy"
                                                        onClick={() =>
                                                            Func.copy(
                                                                this.state
                                                                    .username
                                                                    ? this.state
                                                                          .username
                                                                    : "..."
                                                            )
                                                        }
                                                    ></Icon>
                                                </strong>
                                                {this.state.created_at && (
                                                    <span>
                                                        Tham gia từ{" "}
                                                        {Func.formatDate(
                                                            this.state
                                                                .created_at,
                                                            15
                                                        )}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="account__user-vip">
                                            <Image
                                                src={
                                                    "images/vips/vip" +
                                                    (this.state.vip
                                                        ? this.state.vip
                                                        : 1) +
                                                    ".png"
                                                }
                                            ></Image>
                                        </div>
                                        {/* {this.state.code && (
                                            <div className="account__user-code">
                                                Mã giới thiệu:
                                                <div>
                                                    <span>
                                                        {this.state.code}
                                                    </span>
                                                    <Icon
                                                        type="far"
                                                        className="fa-copy"
                                                        onClick={() =>
                                                            Func.copy(
                                                                this.state.code
                                                            )
                                                        }
                                                    ></Icon>
                                                </div>
                                            </div>
                                        )} */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="account__money">
                        <div className="account__money-balance">
                            <strong>Số dư tài khoản</strong>
                            <span>
                                {this.state.balance
                                    ? Func.formatBalance(this.state.balance)
                                    : "..."}
                            </span>
                        </div>
                        <div className="account__money-link">
                            <div
                                className="account__money-item"
                                style={{ position: "relative" }}
                                onClick={() =>
                                    document.querySelector("#cskh a").click()
                                }
                            >
                                <Icon
                                    type="far"
                                    className="fa-envelope-open-dollar"
                                ></Icon>
                                <span>Nạp tiền</span>
                            </div>
                            <span></span>
                            <NavLink
                                to={Func.getRoute("account.withdraw")}
                                className="account__money-item"
                            >
                                <Icon
                                    type="far"
                                    className="fa-money-from-bracket"
                                ></Icon>
                                <span>Rút tiền</span>
                            </NavLink>
                        </div>
                    </div>
                    <div className="account__menu">
                        <div className="account__menu-item">
                            <Icon
                                type="far"
                                className="fa-arrow-up-right-dots"
                            ></Icon>
                            <NavLink
                                to={Func.getRoute("account.bet")}
                                className="account__money-item"
                            >
                                Lịch sử đặt cược
                            </NavLink>
                            <Icon type="far" className="fa-angles-right"></Icon>
                        </div>
                        <div className="account__menu-item">
                            <Icon
                                type="far"
                                className="fa-money-check-dollar"
                            ></Icon>
                            <NavLink
                                to={Func.getRoute("account.transaction")}
                                className="account__money-item"
                            >
                                Biến động số dư
                            </NavLink>
                            <Icon type="far" className="fa-angles-right"></Icon>
                        </div>
                        <div className="account__menu-item">
                            <Icon
                                type="far"
                                className="fa-money-bill-trend-up"
                            ></Icon>
                            <NavLink
                                to={Func.getRoute("account.recharge.history")}
                                className="account__money-item"
                            >
                                Lịch sử nạp tiền
                            </NavLink>
                            <Icon type="far" className="fa-angles-right"></Icon>
                        </div>
                        <div className="account__menu-item">
                            <Icon
                                type="far"
                                className="fa-money-bill-transfer"
                            ></Icon>
                            <NavLink
                                to={Func.getRoute("account.withdraw.history")}
                                className="account__money-item"
                            >
                                Lịch sử rút tiền
                            </NavLink>
                            <Icon type="far" className="fa-angles-right"></Icon>
                        </div>
                        <div className="account__menu-item">
                            <Icon
                                type="far"
                                className="fa-building-columns"
                            ></Icon>
                            <NavLink
                                to={Func.getRoute("account.bank")}
                                className="account__money-item"
                            >
                                Liên kết ngân hàng
                            </NavLink>
                            <Icon type="far" className="fa-angles-right"></Icon>
                        </div>
                        {/* <div className="account__menu-item">
                     <Icon type="far" className="fa-screen-users"></Icon>
                     <NavLink to={Func.getRoute('account.team')} className="account__money-item">
                        Đội của tôi
                     </NavLink>
                     <Icon type="far" className="fa-angles-right"></Icon>
                  </div> */}
                        <div className="account__menu-item">
                            <Icon type="far" className="fa-lock-keyhole"></Icon>
                            <NavLink
                                to={Func.getRoute("account.password_withdraw")}
                                className="account__money-item"
                            >
                                Đổi mật khẩu rút tiền
                            </NavLink>
                            <Icon type="far" className="fa-angles-right"></Icon>
                        </div>
                        <div className="account__menu-item">
                            <Icon type="far" className="fa-lock-keyhole"></Icon>
                            <NavLink
                                to={Func.getRoute("account.password")}
                                className="account__money-item"
                            >
                                Đổi mật khẩu
                            </NavLink>
                            <Icon type="far" className="fa-angles-right"></Icon>
                        </div>
                        <div className="account__menu-item">
                            <Icon type="far" className="fa-bell"></Icon>
                            <NavLink
                                to={Func.getRoute("notification")}
                                className="account__money-item"
                            >
                                Trung tâm thông báo
                            </NavLink>
                            <Icon type="far" className="fa-angles-right"></Icon>
                        </div>
                        <div className="account__menu-item">
                            <Icon type="far" className="fa-circle-info"></Icon>
                            <NavLink
                                to={Func.getRoute("about_us")}
                                className="account__money-item"
                            >
                                Về chúng tôi
                            </NavLink>
                            <Icon type="far" className="fa-angles-right"></Icon>
                        </div>
                    </div>
                    <div
                        className="account__logout"
                        onClick={this.handleLogout}
                    >
                        Đăng xuất
                    </div>
                    <Partner></Partner>
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(React.memo(Account));
