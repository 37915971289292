import Image from "@/components/comImage/Image";
import React from "react";

class Partner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <React.Fragment {...this.props}>
                <div className="partners">
                    {/* <div className="partners__title">Đồng tài trợ bởi:</div> */}
                    <div className="partners__content">
                        <Image
                            src={"/images/Resorts_World_Sentosa_logo.svg.png"}
                        ></Image>
                        {/* <Image
                            style={{ width: "130px", flex: "0 0 130px" }}
                            src={"/images/unnamed-4653-removebg-preview.png"}
                        ></Image>
                        <Image src={"/images/partners/vingroup.png"}></Image>
                        <Image src={"/images/partners/techcombank.png"}></Image>
                        <Image src={"/images/partners/VietinBank.png"}></Image> */}
                        {/* <Image src={'/images/partners/bidv.png'}></Image> */}
                        {/* <Image src={'/images/partners/MB_bank.png'}></Image> */}
                        {/* <Image src={'/images/partners/Sun-group.png'}></Image> */}
                        {/* <Image src={"/images/partners/VPBank.png"}></Image> */}
                        {/* <Image src={'/images/partners/vib_bank.png'}></Image> */}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Partner;
