import React from "react";
import "./withdraw_history.scss";
import withRouter from "@/app/comRouter/withRouter";
import Header from "@/layouts/comHeader/Header";
import Func from "@/app/comFunc/Func";
import NoData from "@/components/comNoData/NoData";
import Label from "@/components/comLabel/Label";

class WithdrawHistory extends React.Component {
    constructor(props) {
        super(props);
        window.WithdrawHistory = this;
        this.state = {
            withdraws: undefined,
        };
    }
    _isMounted = false;
    componentDidMount = () => {
        this._isMounted = true;
        this.load();
    };
    componentWillUnmount() {
        this._isMounted = false;
    }
    load = () => {
        Func.send(
            Func.getApi("client.mACL.withdraw.index", "path"),
            Func.getApi("client.mACL.withdraw.index", "method"),
            null,
            (res) => {
                if (res.data && res.data.data) {
                    let data = res.data.data;
                    this._isMounted &&
                        this.setState({
                            ...this.state,
                            withdraws: data,
                        });
                }
            },
            (res) => {
                console.clear();
            }
        );
    };
    render() {
        return (
            <React.Fragment>
                <Header
                    heading={Func.getRoute("account.withdraw.history", "text")}
                ></Header>
                {this.state.withdraws && this.state.withdraws.length ? (
                    <div className="withdraw-history__list">
                        {this.state.withdraws.map((item) => {
                            return (
                                <div
                                    key={item._id}
                                    className={
                                        "withdraw-history__item withdraw-history__item-" +
                                        (parseInt(item.status) === 1
                                            ? "success"
                                            : parseInt(item.status) === 2
                                            ? "danger"
                                            : "warning")
                                    }
                                >
                                    <div className="withdraw-history__item-head">
                                        <strong>
                                            {Func.formatDate(item.created_at)}
                                        </strong>
                                        {parseInt(item.status) === 1 ? (
                                            <Label className="label__success">
                                                {item.status_text}
                                            </Label>
                                        ) : parseInt(item.status) === 2 ? (
                                            <Label className="label__danger">
                                                {item.status_text}
                                            </Label>
                                        ) : (
                                            <Label className="label__warning">
                                                {item.status_text}
                                            </Label>
                                        )}
                                    </div>
                                    <div className="withdraw-history__item-row">
                                        <strong>Số tiền rút: </strong>
                                        <strong className="color__danger">
                                            {Func.formatBalance(item.balance)}
                                        </strong>
                                    </div>
                                    <div className="withdraw-history__item-row">
                                        <strong>Tình trạng: </strong>
                                        {parseInt(item.process) === 1 ? (
                                            <strong className="color__success">
                                                {item.process_text}
                                            </strong>
                                        ) : parseInt(item.process) === 2 ? (
                                            <strong className="color__warning">
                                                {item.process_text}
                                            </strong>
                                        ) : (
                                            <strong className="color__default">
                                                {item.process_text}
                                            </strong>
                                        )}
                                    </div>

                                    {parseInt(item.process) === 1 && (
                                        <div className="withdraw-history__item-row">
                                            <strong>Nội dung: </strong>
                                            <span>
                                                {parseInt(item.status) === 1
                                                    ? item.note_approved
                                                    : parseInt(item.status) ===
                                                      2
                                                    ? item.note_fail
                                                    : item.process_text}
                                            </span>
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                ) : (
                    <NoData></NoData>
                )}
            </React.Fragment>
        );
    }
}

export default withRouter(React.memo(WithdrawHistory));
