import React from "react";
import "./team.scss";
import withRouter from "@/app/comRouter/withRouter";
import Header from "@/layouts/comHeader/Header";
import Func from "@/app/comFunc/Func";
import NoData from "@/components/comNoData/NoData";

class Team extends React.Component {
    constructor(props) {
        super(props);
        window.Team = this;
        this.state = {};
    }
    _isMounted = false;
    componentDidMount = () => {
        this._isMounted = true;
    };
    componentWillUnmount() {
        this._isMounted = false;
    }
    render() {
        return (
            <React.Fragment>
                <Header
                    heading={Func.getRoute("account.team", "text")}
                ></Header>
                <div className="team">
                    <NoData></NoData>
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(React.memo(Team));
