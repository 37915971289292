import React from "react";
import "./master.scss";
import { Outlet } from "react-router";
import { Slide, ToastContainer } from "react-toastify";
import "animate.css";
import Func from "@/app/comFunc/Func";
import { io } from "socket.io-client";
import Tab from "../comTab/Tab";
import RealTime from "../comRealTime/RealTime";

class Master extends React.Component {
    constructor(props) {
        super(props);
        window.Master = this;
        this.state = {};
        if (Func.env("REACT_APP_SOCKET_OPEN") === true) {
            let socketUrl =
                Func.env("REACT_APP_MODE_PRODUCTION") === true
                    ? Func.env("REACT_APP_SOCKET_PRODUCTION_URL")
                    : Func.env("REACT_APP_SOCKET_LOCAL_URL");
            this.socket = io(socketUrl, { autoConnect: false });
        }
    }
    _isMounted = false;
    componentDidMount() {
        window.addEventListener("focus", this.onFocus);
        window.addEventListener("blur", this.onBlur);
        window.addEventListener("visibilitychange", this.onVisibilityChange);
        Func.setStorage({ is_sending: "false" });
        if (Func.env("REACT_APP_SOCKET_OPEN") === true) {
            this.socket.open();
        }
        this._isMounted = true;
    }
    componentWillUnmount = () => {
        window.removeEventListener("focus", this.onFocus);
        window.removeEventListener("blur", this.onBlur);
        window.removeEventListener("visibilitychange", this.onVisibilityChange);
        Func.removeStorage("hide_sidebar");
        if (Func.env("REACT_APP_SOCKET_OPEN") === true) {
            this.socket.close();
        }
        this._isMounted = false;
    };
    onBlur = () => {};
    onFocus = () => {};
    onVisibilityChange = () => {};
    render() {
        return (
            <React.Fragment>
                <section className="screen">
                    <Outlet></Outlet>
                </section>
                <ToastContainer
                    position="top-center"
                    autoClose={2000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss={false}
                    draggable
                    pauseOnHover={false}
                    transition={Slide}
                    limit={3}
                />
                <Tab></Tab>
                <RealTime></RealTime>
            </React.Fragment>
        );
    }
}

export default Master;
