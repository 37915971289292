import Func from "@/app/comFunc/Func";
import React from "react";
import "./input.scss";
import NumberFormat from "react-number-format";

class Input extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    onFormat = (event) => {
        let string = event.target.value;
        let result = string;
        if (this.props.format) {
            switch (this.props.format) {
                case "Alphabet":
                    event.target.value = Func.formatAlphabet(
                        string,
                        null,
                        this.props.nospace ? false : true
                    );
                    this.cutString(event);
                    break;
                case "alphabet":
                    event.target.value = Func.formatAlphabet(
                        string,
                        1,
                        this.props.nospace ? false : true
                    );
                    this.cutString(event);
                    break;
                case "ALPHABET":
                    event.target.value = Func.formatAlphabet(
                        string,
                        2,
                        this.props.nospace ? false : true
                    );
                    this.cutString(event);
                    break;
                case "Alphadash":
                    event.target.value = Func.formatAlphadash(
                        string,
                        null,
                        this.props.nospace ? false : true
                    );
                    this.cutString(event);
                    break;
                case "alphadash":
                    event.target.value = Func.formatAlphadash(
                        string,
                        1,
                        this.props.nospace ? false : true
                    );
                    this.cutString(event);
                    break;
                case "ALPHADASH":
                    event.target.value = Func.formatAlphadash(
                        string,
                        2,
                        this.props.nospace ? false : true
                    );
                    this.cutString(event);
                    break;
                case "AlphabetVN":
                    event.target.value = Func.formatAlphabetVN(
                        string,
                        null,
                        this.props.nospace ? false : true
                    );
                    this.cutString(event);
                    break;
                case "alphabetvn":
                    event.target.value = Func.formatAlphabetVN(
                        string,
                        1,
                        this.props.nospace ? false : true
                    );
                    this.cutString(event);
                    break;
                case "ALPHABETVN":
                    event.target.value = Func.formatAlphabetVN(
                        string,
                        2,
                        this.props.nospace ? false : true
                    );
                    this.cutString(event);
                    break;
                case "AlphadashVN":
                    event.target.value = Func.formatAlphadashVN(
                        string,
                        null,
                        this.props.nospace ? false : true
                    );
                    this.cutString(event);
                    break;
                case "alphadashvn":
                    event.target.value = Func.formatAlphadashVN(
                        string,
                        1,
                        this.props.nospace ? false : true
                    );
                    this.cutString(event);
                    break;
                case "ALPHADASHVN":
                    event.target.value = Func.formatAlphadashVN(
                        string,
                        2,
                        this.props.nospace ? false : true
                    );
                    this.cutString(event);
                    break;
                case "Slug":
                    event.target.value = Func.formatSlug(string);
                    this.cutString(event);
                    break;
                case "slug":
                    event.target.value = Func.formatSlug(string, 1);
                    this.cutString(event);
                    break;
                case "SLUG":
                    event.target.value = Func.formatSlug(string, 2);
                    this.cutString(event);
                    break;
                case "phonevn":
                    event.target.value = Func.formatPhoneVN(
                        string,
                        this.props.max || 10
                    );
                    this.cutString(event);
                    break;
                default:
                    break;
            }
        } else {
            this.cutString(event);
        }
        this.props.onInput(event, result);
    };
    onBlur = (event) => {
        if (this.props.format) {
            let value = event.target.value;
            value = value.toString();
            switch (this.props.format) {
                case "slug":
                    event.target.value = value.replace(/^-+|-+$/g, "");
                    break;
                case "numeric":
                    let minNumeric = Func.filterNumeric(this.props.min);
                    value = Func.filterNumeric(value);
                    !value && (event.target.value = minNumeric);
                    if (value < minNumeric && event && event.target) {
                        event.target.value = minNumeric;
                    }
                    break;
                case "integer":
                    let minInteger = Func.filterNumeric(this.props.min);
                    value = Func.filterNumeric(value);
                    !value && (event.target.value = minInteger);
                    if (value < minInteger && event && event.target) {
                        event.target.value = minInteger;
                    }
                    break;
                case "float":
                    let minFloat = Func.filterNumeric(this.props.min);
                    value = Func.filterNumeric(value);
                    !value && (event.target.value = minFloat);
                    if (value < minFloat && event && event.target) {
                        event.target.value = minFloat;
                    }
                    break;
                default:
                    break;
            }
        }
        this.onFormat(event);
    };
    cutString = (event) => {
        if (this.props.max) {
            let string = event.target.value;
            let max = parseInt(this.props.max);
            if (max > 0) {
                event.target.value = string.toString().substring(0, max);
            }
        }
    };
    render() {
        return this.props.format ? (
            <div className="input">
                {this.props.format === "numeric" ||
                this.props.format === "integer" ||
                this.props.format === "float" ? (
                    <NumberFormat
                        onBlur={this.onBlur}
                        prefix={
                            this.props.prefix ? this.props.prefix : undefined
                        }
                        suffix={
                            this.props.suffix ? this.props.suffix : undefined
                        }
                        value={
                            this.props.value
                                ? this.props.value
                                : this.props.min
                                ? Func.filterNumeric(this.props.min)
                                : ""
                        }
                        thousandSeparator=","
                        allowedDecimalSeparators={[","]}
                        thousandsGroupStyle="thousand"
                        decimalScale={
                            this.props.format === "numeric" ||
                            this.props.format === "float"
                                ? this.props.decimal || 2
                                : null
                        }
                        decimalSeparator={
                            this.props.format === "numeric" ||
                            this.props.format === "float"
                                ? "."
                                : null
                        }
                        allowEmptyFormatting={true}
                        placeholder={this.props.placeholder || ""}
                        allowNegative={
                            this.props.format === "integer" ||
                            this.props.format === "float"
                                ? false
                                : true
                        }
                        isAllowed={(inputObj) => true}
                        name={this.props.name || ""}
                        className={this.props.className || ""}
                        disabled={
                            this.props.disabled ? this.props.disabled : false
                        }
                        readOnly={
                            this.props.readOnly ? this.props.readOnly : false
                        }
                        onValueChange={(values, sourceInfo) => {
                            let value = values.floatValue;
                            if (this.props.max) {
                                let max = Func.filterNumeric(this.props.max);
                                if (
                                    value > max &&
                                    sourceInfo.event &&
                                    sourceInfo.event.target
                                ) {
                                    sourceInfo.event.target.value = max;
                                    value = max;
                                }
                            }
                            // if (this.props.min) {
                            //    let min = Func.filterNumeric(this.props.min);
                            //    console.log(typeof value);
                            //    !value && (sourceInfo.event.target.value = min);
                            //    if (value < min && sourceInfo.event && sourceInfo.event.target) {
                            //       sourceInfo.event.target.value = min;
                            //    }
                            // }
                            return this.props.onInput(sourceInfo.event, value);
                        }}
                    ></NumberFormat>
                ) : (
                    <React.Fragment>
                        <input
                            {...this.props}
                            onInput={this.onFormat}
                            onBlur={this.onBlur}
                            onFocus={this.onFormat}
                            onChange={this.onFormat}
                            onPaste={this.onPaste}
                            value={this.props.value ? this.props.value : ""}
                            disabled={
                                this.props.disabled
                                    ? this.props.disabled
                                    : false
                            }
                            readOnly={
                                this.props.readOnly
                                    ? this.props.readOnly
                                    : false
                            }
                        />
                        {this.props.max && (
                            <span>
                                {this.props.value && this.props.value.length
                                    ? this.props.value.length
                                    : 0}
                                /{parseInt(this.props.max)}
                            </span>
                        )}
                    </React.Fragment>
                )}
            </div>
        ) : (
            <React.Fragment>
                <div className="input">
                    <input
                        {...this.props}
                        onInput={this.onFormat}
                        onBlur={this.onBlur}
                        onFocus={this.onFormat}
                        onChange={this.onFormat}
                        onPaste={this.onPaste}
                        value={this.props.value ? this.props.value : ""}
                        disabled={
                            this.props.disabled ? this.props.disabled : false
                        }
                        readOnly={
                            this.props.readOnly ? this.props.readOnly : false
                        }
                    />
                    {this.props.max && (
                        <span>
                            {this.props.value && this.props.value.length
                                ? this.props.value.length
                                : 0}
                            /{parseInt(this.props.max)}
                        </span>
                    )}
                </div>
            </React.Fragment>
        );
    }
}

export default Input;
