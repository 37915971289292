import React from "react";
import "./password.scss";
import withRouter from "@/app/comRouter/withRouter";
import Form from "@/components/comForm/Form";
import Input from "@/components/comForm/comInput/Input";
import Func from "@/app/comFunc/Func";
import Icon from "@/components/comIcon/Icon";
import Button from "@/components/comButton/Button";
import Sending from "@/components/comSending/Sending";
import Validator from "@/app/comValidator/Validator";
import { Navigate } from "react-router-dom";
import Header from "@/layouts/comHeader/Header";
import { PageSlide, PageWelcome } from "../../comHome/Home";
import Partner from "@/screens/comAuth/Partner";

const rules = [
    {
        field: "password",
        method: "isEmpty",
        validWhen: false,
        message: "Mật khẩu mới không được bỏ trống.",
    },
    {
        field: "password",
        method: "isLength",
        args: [{ min: 6, max: 30 }],
        validWhen: true,
        message: "Mật khẩu mới từ 6 tới 30 ký tự.",
    },
    {
        field: "password_confirmation",
        method: "isEmpty",
        validWhen: false,
        message: "Nhắc lại mật khẩu mới không được bỏ trống.",
    },
    {
        field: "password_confirmation",
        method: "isLength",
        args: [{ min: 6, max: 30 }],
        validWhen: true,
        message: "Nhắc lại mật khẩu mới từ 6 tới 30 ký tự.",
    },
];

class Password extends React.Component {
    constructor(props) {
        super(props);
        window.Password = this;
        this.state = {
            is_sending: Func.getStorage("is_sending"),
            password: null,
            password_confirmation: null,
            show_password: false,
            show_password_confirmation: false,
            submited: false,
            errors: {},
        };
        this.validator = new Validator(rules);
    }
    componentDidMount = () => {
        this._isMounted = true;
    };
    _isMounted = false;
    componentWillUnmount = () => {
        this._isMounted = false;
    };
    handleShowPassword = (event) => {
        let checkClass = event.target.classList.contains("fa-eye");
        this.setState({
            ...this.state,
            show_password: checkClass ? true : false,
        });
    };
    handleShowPasswordConfirm = (event) => {
        let checkClass = event.target.classList.contains("fa-eye");
        this.setState({
            ...this.state,
            show_password_confirmation: checkClass ? true : false,
        });
    };

    handleSubmit = (event) => {
        event.preventDefault();
        this.setState(
            {
                ...this.state,
                errors: this.validator.valid(this.state),
            },
            () => {
                if (Func.checkObjectEmpty(this.state.errors)) {
                    Func.send(
                        Func.getApi("client.mACL.customer.password", "path", {
                            customer: Func.getStorage("id"),
                        }),
                        Func.getApi("client.mACL.customer.password", "method"),
                        {
                            password: this.state.password,
                            password_confirmation:
                                this.state.password_confirmation,
                        },
                        (res) => {
                            res.data.message &&
                                Func.notifySuccess(res.data.message);
                            this._isMounted &&
                                this.setState({
                                    submited: true,
                                });
                        },
                        (res) => {
                            Func.handleErrors(res, this);
                        }
                    );
                }
            }
        );
    };

    render() {
        return this.state.submited ? (
            <Navigate to={Func.getRoute("account")}></Navigate>
        ) : (
            <React.Fragment>
                <Header
                    heading={Func.getRoute("account.password", "text")}
                ></Header>
                <div id="auth" className="auth">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <PageSlide></PageSlide>
                                <PageWelcome></PageWelcome>
                                <Form
                                    onSubmit={this.handleSubmit}
                                    id="auth__form"
                                    className="auth__form"
                                >
                                    <div className="auth__form-content">
                                        <div className="auth__form-item">
                                            <div className="auth__form-icon">
                                                <Input
                                                    onInput={(event) =>
                                                        Func.setValueInput(
                                                            event,
                                                            this
                                                        )
                                                    }
                                                    type={
                                                        this.state.show_password
                                                            ? "text"
                                                            : "password"
                                                    }
                                                    name="password"
                                                    autoComplete="new-password"
                                                    className={
                                                        this.state.errors
                                                            .password &&
                                                        "validator__error"
                                                    }
                                                    placeholder="Mật khẩu mới (bắt buộc)"
                                                    readOnly={
                                                        this.state.is_sending
                                                    }
                                                    value={this.state.password}
                                                ></Input>
                                                <Icon
                                                    type="fas"
                                                    onClick={
                                                        this.handleShowPassword
                                                    }
                                                    className={
                                                        this.state.show_password
                                                            ? "fa-eye-slash"
                                                            : "fa-eye"
                                                    }
                                                ></Icon>
                                            </div>
                                            {this.state.errors.password && (
                                                <div className="validator__error">
                                                    {this.state.errors.password}
                                                </div>
                                            )}
                                        </div>
                                        <div className="auth__form-item">
                                            <div className="auth__form-icon">
                                                <Input
                                                    onInput={(event) =>
                                                        Func.setValueInput(
                                                            event,
                                                            this
                                                        )
                                                    }
                                                    type={
                                                        this.state
                                                            .show_password_confirmation
                                                            ? "text"
                                                            : "password"
                                                    }
                                                    name="password_confirmation"
                                                    autoComplete="new-password"
                                                    className={
                                                        this.state.errors
                                                            .password_confirmation &&
                                                        "validator__error"
                                                    }
                                                    placeholder="Nhắc lại mật khẩu mới (bắt buộc)"
                                                    readOnly={
                                                        this.state.is_sending
                                                    }
                                                    value={
                                                        this.state
                                                            .password_confirmation
                                                    }
                                                ></Input>
                                                <Icon
                                                    type="fas"
                                                    onClick={
                                                        this
                                                            .handleShowPasswordConfirm
                                                    }
                                                    className={
                                                        this.state
                                                            .show_password_confirmation
                                                            ? "fa-eye-slash"
                                                            : "fa-eye"
                                                    }
                                                ></Icon>
                                            </div>
                                            {this.state.errors
                                                .password_confirmation && (
                                                <div className="validator__error">
                                                    {
                                                        this.state.errors
                                                            .password_confirmation
                                                    }
                                                </div>
                                            )}
                                        </div>
                                        <div className="auth__form-item">
                                            <Button
                                                disabled={this.state.is_sending}
                                                type="submit"
                                                className="auth__form-button btn btn__warning btn__big"
                                                id="auth__form-button"
                                            >
                                                {this.state.is_sending ? (
                                                    <Sending></Sending>
                                                ) : (
                                                    "Cập nhật"
                                                )}
                                            </Button>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                    <Partner></Partner>
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(React.memo(Password));
