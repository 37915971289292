import React from "react";
import "./form.scss";

class Form extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <form {...this.props} method={this.props.method || "GET"}>
                {this.props.children}
            </form>
        );
    }
}

export default Form;
