import React from "react";
import "./trend.scss";
import withRouter from "@/app/comRouter/withRouter";
import Func from "@/app/comFunc/Func";
import Header from "@/layouts/comHeader/Header";
import NoData from "@/components/comNoData/NoData";

class Trend extends React.Component {
    constructor(props) {
        super(props);
        window.Trend = this;
        this.state = {};
    }
    _isMounted = false;
    componentDidMount = () => {
        this._isMounted = true;
    };
    componentWillUnmount() {
        this._isMounted = false;
    }
    render() {
        return (
            <React.Fragment>
                <Header heading={Func.getRoute("trend", "text")}></Header>
                <div className="trend">
                    <NoData></NoData>
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(React.memo(Trend));
