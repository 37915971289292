import React from "react";
import "./clock.scss";
import Icon from "../comIcon/Icon";
class Clock extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            date: new Date().toLocaleDateString(),
            time: new Date().toLocaleTimeString(),
        };
    }

    componentDidMount() {
        this.realTimeClock = setInterval(() => {
            this.setState({
                ...this.state,
                date: new Date().toLocaleDateString(),
                time: new Date().toLocaleTimeString(),
            });
        }, 1000);
    }

    componentWillUnmount = () => {
        clearInterval(this.realTimeClock);
    };

    render() {
        return (
            <div
                className={"clock " + (this.props.class || "")}
                id={this.props.id}
            >
                <Icon type="fas" className="fa-calendar-alt"></Icon>
                <span>
                    {this.state.date} {this.state.time}
                </span>
            </div>
        );
    }
}

export default Clock;
