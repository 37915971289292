import React from "react";
import "./slide_image.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const settingsDefault = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    adaptiveHeight: false,
};
class SlideImage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    _isMounted = false;
    componentDidMount = () => {
        this._isMounted = true;
    };
    componentWillUnmount() {
        this._isMounted = false;
    }
    render() {
        let settings = this.props.settings
            ? this.props.settings
            : settingsDefault;
        return (
            this.props.array && (
                <div
                    className={
                        "slide-container " +
                        (this.props.className ? this.props.className : "")
                    }
                >
                    <div className="slide-image">
                        <Slider {...settings}>
                            {this.props.array.map((item, index) => (
                                <img
                                    className="slide-image__item"
                                    key={index}
                                    src={item.url}
                                    alt={item.alt}
                                />
                            ))}
                        </Slider>
                    </div>
                </div>
            )
        );
    }
}

export default SlideImage;
