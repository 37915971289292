import Func from "@/app/comFunc/Func";
import React from "react";
import "./label.scss";

class Label extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <label
                {...this.props}
                className={"label " + (this.props.className || "")}
                onClick={(event) => Func.copy(this.props.children)}
                title={
                    this.props.title
                        ? Func.stripHtml(this.props.title)
                        : Func.stripHtml(this.props.children)
                }
            >
                {this.props.children}
            </label>
        );
    }
}

export default Label;
