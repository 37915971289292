import React from "react";
import "./result.scss";
import withRouter from "@/app/comRouter/withRouter";
import Header from "@/layouts/comHeader/Header";
import Func from "@/app/comFunc/Func";
import { NavLink } from "react-router-dom";
import { gameTatCa } from "../comHome/Home";
import Icon from "@/components/comIcon/Icon";
import DiceResult from "@/components/comDiceResult/DiceResult";

class Result extends React.Component {
    constructor(props) {
        super(props);
        window.Result = this;
        this.state = {
            game_three_dice_five_min: undefined,
            game_three_dice_one_min: undefined,
            game_three_dice_ten_min: undefined,
            game_three_dice_three_min: undefined,
        };
    }
    _isMounted = false;
    componentDidMount = () => {
        this._isMounted = true;
        this.load();
    };
    componentWillUnmount() {
        this._isMounted = false;
    }
    load = () => {
        Func.send(
            Func.getApi("client.mGame.result.index", "path"),
            Func.getApi("client.mGame.result.index", "method"),
            null,
            (res) => {
                if (res.data && res.data.data) {
                    let data = res.data.data;
                    this._isMounted &&
                        this.setState({
                            ...this.state,
                            game_three_dice_five_min:
                                data.game_three_dice_five_min,
                            game_three_dice_one_min:
                                data.game_three_dice_one_min,
                            game_three_dice_ten_min:
                                data.game_three_dice_ten_min,
                            game_three_dice_three_min:
                                data.game_three_dice_three_min,
                        });
                }
            },
            (res) => {
                console.clear();
            }
        );
    };
    render() {
        return (
            <React.Fragment>
                <Header heading={Func.getRoute("result", "text")}></Header>
                <div className="result">
                    {gameTatCa.map((game, index) => {
                        return game.enabled ? (
                            <NavLink
                                to={
                                    game.linkResult
                                        ? Func.getRoute(game.linkResult)
                                        : "#"
                                }
                                key={game.name + "_enabled_" + index}
                                className="result__item"
                            >
                                <div className="result__item-info">
                                    <img src={game.image} alt={game.name} />
                                    <h3>
                                        <strong>{game.name}</strong>
                                        {game.code && (
                                            <React.Fragment>
                                                {game.code ===
                                                    "game_three_dice_five_min" &&
                                                this.state
                                                    .game_three_dice_five_min ? (
                                                    <React.Fragment>
                                                        <span>
                                                            Kỳ:{" "}
                                                            {
                                                                this.state
                                                                    .game_three_dice_five_min
                                                                    .no
                                                            }
                                                        </span>
                                                        <DiceResult
                                                            array={[
                                                                this.state
                                                                    .game_three_dice_five_min
                                                                    .bom_1,
                                                                this.state
                                                                    .game_three_dice_five_min
                                                                    .bom_2,
                                                                this.state
                                                                    .game_three_dice_five_min
                                                                    .bom_3,
                                                            ]}
                                                        ></DiceResult>
                                                    </React.Fragment>
                                                ) : (
                                                    <React.Fragment></React.Fragment>
                                                )}
                                                {game.code ===
                                                    "game_three_dice_one_min" &&
                                                this.state
                                                    .game_three_dice_one_min ? (
                                                    <React.Fragment>
                                                        <span>
                                                            Kỳ:{" "}
                                                            {
                                                                this.state
                                                                    .game_three_dice_one_min
                                                                    .no
                                                            }
                                                        </span>
                                                        <DiceResult
                                                            array={[
                                                                this.state
                                                                    .game_three_dice_one_min
                                                                    .bom_1,
                                                                this.state
                                                                    .game_three_dice_one_min
                                                                    .bom_2,
                                                                this.state
                                                                    .game_three_dice_one_min
                                                                    .bom_3,
                                                            ]}
                                                        ></DiceResult>
                                                    </React.Fragment>
                                                ) : (
                                                    <React.Fragment></React.Fragment>
                                                )}
                                                {game.code ===
                                                    "game_three_dice_ten_min" &&
                                                this.state
                                                    .game_three_dice_ten_min ? (
                                                    <React.Fragment>
                                                        <span>
                                                            Kỳ:{" "}
                                                            {
                                                                this.state
                                                                    .game_three_dice_ten_min
                                                                    .no
                                                            }
                                                        </span>
                                                        <DiceResult
                                                            array={[
                                                                this.state
                                                                    .game_three_dice_ten_min
                                                                    .bom_1,
                                                                this.state
                                                                    .game_three_dice_ten_min
                                                                    .bom_2,
                                                                this.state
                                                                    .game_three_dice_ten_min
                                                                    .bom_3,
                                                            ]}
                                                        ></DiceResult>
                                                    </React.Fragment>
                                                ) : (
                                                    <React.Fragment></React.Fragment>
                                                )}
                                                {game.code ===
                                                    "game_three_dice_three_min" &&
                                                this.state
                                                    .game_three_dice_three_min ? (
                                                    <React.Fragment>
                                                        <span>
                                                            Kỳ:{" "}
                                                            {
                                                                this.state
                                                                    .game_three_dice_three_min
                                                                    .no
                                                            }
                                                        </span>
                                                        <DiceResult
                                                            array={[
                                                                this.state
                                                                    .game_three_dice_three_min
                                                                    .bom_1,
                                                                this.state
                                                                    .game_three_dice_three_min
                                                                    .bom_2,
                                                                this.state
                                                                    .game_three_dice_three_min
                                                                    .bom_3,
                                                            ]}
                                                        ></DiceResult>
                                                    </React.Fragment>
                                                ) : (
                                                    <React.Fragment></React.Fragment>
                                                )}
                                            </React.Fragment>
                                        )}
                                    </h3>
                                </div>
                                <Icon
                                    type="far"
                                    className="fa-angle-right"
                                ></Icon>
                            </NavLink>
                        ) : (
                            <React.Fragment
                                key={game.name + "_enabled_" + index}
                            ></React.Fragment>
                        );
                    })}
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(React.memo(Result));
