import React from "react";
import "./button.scss";
class Button extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <button {...this.props} type={this.props.type || "button"}>
                {this.props.children}
            </button>
        );
    }
}

export default Button;
