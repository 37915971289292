import React from "react";
import "./option.scss";
import Select from "react-select";

const styles = {
    option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? "#333" : "#333",
        padding: "5px 10px",
        background: state.isSelected
            ? "#dbdbdb !important"
            : "#ffffff !important",
        "&:hover": {
            background: "#e9e9e9 !important",
        },
        fontSize: "13px",
        fontWeight: "400",
    }),
    control: (provided, state) => {
        let className = state.selectProps.className;
        return {
            ...provided,
            background: state.selectProps.isDisabled ? "#eaeef1" : "#ffffff",
            outline: "none !important",
            borderColor:
                className && className.split(" ").includes("validator__error")
                    ? "red"
                    : "#e9ecef",
            minHeight: "30px",
            fontSize: "13px",
            borderRadius: "3px",
            cursor: "pointer",
            width: "100%",
            maxWidth: "100%",
            "&:hover": {
                boxShadow: "none",
                outline: "none !important",
            },
            "&:focus": {
                boxShadow: "none",
                outline: "none !important",
            },
            boxShadow: "none",
        };
    },
    indicatorSeparator: (provided, state) => ({
        ...provided,
        display: "none",
    }),
    placeholder: (provided, state) => ({
        ...provided,
        whiteSpace: "nowrap",
        fontSize: "13px",
        color: "#6c757d",
        fontWeight: "400",
        opacity: "1",
    }),
    menu: (provided, state) => ({
        ...provided,
        margin: "2px 0px 0px 0px",
        ">*": {
            scrollbarWidth: "thin",
            scrollbarColor: "#6c757d #e9ecef",
            "&::-webkit-scrollbar": {
                width: "8px",
                height: "8px",
            },
            "&::-webkit-scrollbar-track": {
                background: "#e9ecef",
            },
            "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#6c757d",
                border: "1px solid #e9ecef",
            },
        },
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        width: "calc(100% - 30px)",
        padding: "10px",
        minHeight: "32px",
    }),
    indicatorsContainer: (provided, state) => ({
        ...provided,
        width: "30px",
        color: "#d6d6d6",
        overflow: "hidden",
        svg: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "15px",
            height: "15px",
        },
        path: {
            color: "#00000091",
        },
        ">div": {
            paddingTop: "6px",
            paddingBottom: "6px",
        },
    }),
    singleValue: (provided, state) => ({
        ...provided,
        color: "#333",
        margin: "0 2px",
        padding: "0",
    }),
    input: (provided, state) => ({
        ...provided,
        color: "#333",
        margin: "0 2px",
        padding: "0",
    }),
};

class Option extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    noOptionsMessage = () => "Không có tùy chọn phù hợp !";
    render() {
        return (
            <Select
                {...this.props}
                styles={styles}
                isSearchable={this.props.isSearchable === true ? true : false}
                isLoading={false}
                isClearable={this.props.isClearable === false ? false : true}
                isDisabled={
                    this.props.isDisabled === true ||
                    this.props.disabled === true ||
                    this.props.readOnly === true
                        ? true
                        : false
                }
                noOptionsMessage={this.noOptionsMessage}
            ></Select>
        );
    }
}

export default Option;
