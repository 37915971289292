import React from "react";
import "./transaction.scss";
import withRouter from "@/app/comRouter/withRouter";
import Header from "@/layouts/comHeader/Header";
import Func from "@/app/comFunc/Func";
import NoData from "@/components/comNoData/NoData";
import Icon from "@/components/comIcon/Icon";

class Transaction extends React.Component {
    constructor(props) {
        super(props);
        window.Transaction = this;
        this.state = {
            transactions: undefined,
        };
    }
    _isMounted = false;
    componentDidMount = () => {
        this._isMounted = true;
        this.load();
    };
    componentWillUnmount() {
        this._isMounted = false;
    }
    load = () => {
        Func.send(
            Func.getApi("client.mACL.transaction.index", "path"),
            Func.getApi("client.mACL.transaction.index", "method"),
            null,
            (res) => {
                if (res.data && res.data.data) {
                    let data = res.data.data;
                    this._isMounted &&
                        this.setState({
                            ...this.state,
                            transactions: data,
                        });
                }
            },
            (res) => {
                console.clear();
            }
        );
    };
    render() {
        return (
            <React.Fragment>
                <Header
                    heading={Func.getRoute("account.transaction", "text")}
                ></Header>
                <div className="transaction">
                    {this.state.transactions &&
                    this.state.transactions.length ? (
                        <div className="transaction__list">
                            {this.state.transactions.map((item) => {
                                return (
                                    <div
                                        key={item._id}
                                        className={
                                            "transaction__item transaction__item-" +
                                            (item.balance_before <=
                                            item.balance_after
                                                ? "success"
                                                : "danger")
                                        }
                                    >
                                        <span>{item.note}</span>
                                        <Icon
                                            type="fas"
                                            className="fa-copy"
                                            onClick={() => Func.copy(item.note)}
                                        ></Icon>
                                    </div>
                                );
                            })}
                        </div>
                    ) : (
                        <NoData></NoData>
                    )}
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(React.memo(Transaction));
