import React from "react";
import AppRoute from "@/app/comRouter/Router";

class App extends React.Component {
    render() {
        return <AppRoute></AppRoute>;
    }
}

export default App;
